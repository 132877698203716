import { Routes } from '@crema/constants/Routes';
import React from 'react';

const Signin = React.lazy(() => import('../../../modules/auth/Signin'));
const ForgotPassword = React.lazy(
  () => import('../../../modules/auth/ForgetPassword/ForgetPassword'),
);
const ResetPassword = React.lazy(
  () => import('../../../modules/auth/ResetPassword'),
);
export const authRouteConfig = [
  {
    path: Routes.signIn,
    element: <Signin />,
  },
  {
    path: Routes.forget,
    element: <ForgotPassword />,
  },
  {
    path: Routes.reset,
    element: <ResetPassword />,
  },
];
