import * as yup from 'yup';

import { MESSAGE } from './MessageList';

export const validateUnRequiredDate = yup
  .string()
  .test('is-valid-date', MESSAGE.MSG002, (value) => isValidDate(value))
  .nullable();

export const validateRequiredDate = yup
  .string()
  .required(MESSAGE.MSG001)
  .test('is-valid-date', MESSAGE.MSG002, (value) => isValidDate(value));

export const isValidDate = (value?: string) => {
  try {
    if (!value) return true;

    const isLeapYear = (year: number) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    // Extract the date part from the ISO string
    const [datePart] = new Date(value).toISOString().split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    // Handle February 29th validation in leap years
    if (month === 2 && day === 29) {
      return isLeapYear(year); // Only valid if it's a leap year
    }

    // Validate day ranges for other months
    const daysInMonth = [
      31,
      isLeapYear(year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
    return (
      month >= 1 && month <= 12 && day > 0 && day <= daysInMonth[month - 1]
    );
  } catch (error) {
    return false;
  }
};

const EMAIL_REGEX =
  /^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

const PHONE_REGEX =
  /^0\d{1}-\d{4}-\d{4}$|^0\d{2}-\d{3}-\d{4}$|^0\d{3}-\d{2}-\d{4}$|^0\d{4}-\d{1}-\d{4}$|^0\d{2}-\d{4}-\d{4}$|^0\d{3}-\d{3}-\d{4}$|^0\d{4}-\d{2}-\d{4}$/;

export const phoneValidation = yup
  .string()
  .matches(PHONE_REGEX, MESSAGE.MSG002);

export const emailValidation = yup
  .string()
  .matches(EMAIL_REGEX, MESSAGE.MSG002);

export const multipleEmailValidation = yup
  .string()
  .test('multiple-emails', MESSAGE.MSG002, function (value) {
    if (!value) return true;
    const emails = value.split(',').map((email) => email.trim());
    const emailRegex = EMAIL_REGEX;
    // Check if every email matches the regex
    const isValid = emails.every((email) => emailRegex.test(email));

    return isValid || this.createError({ message: MESSAGE.MSG002 });
  });

export const emailRequiredValidation = yup
  .string()
  .required(MESSAGE.MSG001)
  .matches(EMAIL_REGEX, MESSAGE.MSG002);

export const passwordValidation = yup
  .string()
  .trim()
  .required(MESSAGE.MSG001)
  .min(8, MESSAGE.MSG002)
  .max(255, MESSAGE.MSG002)
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/, MESSAGE.MSG002);
