import React, { Ref } from 'react';
import { NavLink } from 'react-router-dom';

const AppNavLink = React.forwardRef(function AppNavLink(
  { activeClassName, className, ...rest }: any,
  ref: Ref<HTMLAnchorElement> | null,
) {
  return (
    <NavLink
      ref={ref}
      to={rest.to}
      {...rest}
      className={({ isActive }) =>
        isActive ? `${activeClassName} ${className}` : className
      }
    >
      {rest.children}
    </NavLink>
  );
});

export default AppNavLink;
