import { ERROR, baseApiUrl } from '@crema/constants/AppConst';
import axios, { AxiosResponse } from 'axios';

const apiConfig = axios.create({
  baseURL: baseApiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});
apiConfig.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token'); // Assuming you store your token in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err: any) => {
    return Promise.reject(err);
  },
);
apiConfig.interceptors.response.use(
  (res: AxiosResponse<any, any>) => res,
  (err: any) => {
    if (err && err.status === ERROR.Unauthorized) {
      console.log('Need to back sign in');
    }
    return Promise.reject(err);
  },
);
export default apiConfig;
