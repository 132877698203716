import { TextTooltip } from '@crema/components/Material_v5';
import { AppColors } from '@crema/constants/AppColors';
import { Fonts } from '@crema/constants/AppEnums';
import { EMPTY_VALUE } from '@crema/constants/AppString';
import { Routes } from '@crema/constants/Routes';
import { useAppStateContext } from '@crema/context/AppContextProvider/StateContextProvider';
import { useAuthMethod } from '@crema/hooks/AuthHooks';
import { Button, Popover, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import isArray from 'lodash/isArray';
import { useEffect, useState } from 'react';
import useIntl from 'react-intl/lib/src/components/useIntl';
import { useNavigate } from 'react-router-dom';

import {
  AccountIcon,
  DepartmentIcon,
  DropdownIcon,
} from '../../../../../assets/icon';
import AppLogo from '../../components/AppLogo';
import AppBanner from './AppBanner';

const AppHeader = () => {
  const { messages } = useIntl();
  const { appState, userState } = useAppStateContext();
  const { logout } = useAuthMethod();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [departmentAnchorEl, setDepartmentAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string>(
    messages['header.department'] as string,
  );

  useEffect(() => {
    if (userState.departmentSelect) {
      setSelected(userState.departmentSelect);
    }
  }, [userState.departmentSelect]);

  const handleClickPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
    setAnchor: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>,
  ) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDepartment = () => {
    setDepartmentAnchorEl(null);
  };

  const onGoHome = () => {
    navigate(Routes.home);
  };

  const onChange = (label: string) => {
    setSelected(label);
    setDepartmentAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const openDepartment = Boolean(departmentAnchorEl);
  const idAccount = open ? 'account-popover' : undefined;
  const idDepart = openDepartment ? 'department-popover' : undefined;

  return (
    <AppBar
      position="relative"
      color="inherit"
      sx={{
        boxShadow: 'none',
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        backgroundColor: 'background.paper',
        width: '100%',
        height: '48px !important',
        marginTop: appState && appState.msg ? '30px' : 0,
      }}
      className="app-bar"
    >
      <AppBanner msg={appState.msg} type={appState.type} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box',
          height: '48px !important',
          paddingLeft: { xs: 5 },
          paddingRight: { xs: 5, md: 7.5, xl: 12.5 },
        }}
      >
        <Button onClick={onGoHome}>
          <AppLogo />
        </Button>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TextTooltip
              content={userState?.currentOffices?.name ?? EMPTY_VALUE}
              display={userState?.currentOffices?.name ?? EMPTY_VALUE}
              maxWidth="112px"
              sxDisplay={{
                color: AppColors.light.gray,
                fontSize: '11px',
              }}
            />
            <Button
              sx={{
                ml: 2,
                width: '200px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              aria-describedby={idDepart}
              onClick={(event) =>
                handleClickPopover(event, setDepartmentAnchorEl)
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: ' center',
                  gap: '4px',
                  alignItems: 'center',
                }}
              >
                <DepartmentIcon />
                <Typography
                  sx={{
                    color: AppColors.light.black,
                    maxWidth: '120px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    lineHeight: '23px',
                    textTransform: 'none',
                  }}
                >
                  {selected}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DropdownIcon />
              </Box>
            </Button>
            <Popover
              id={idDepart}
              open={openDepartment}
              anchorEl={departmentAnchorEl}
              onClose={handleCloseDepartment}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Button>
                <Box
                  sx={{
                    width: '190px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    alignItems: 'flex-start',
                    color: AppColors.light.black,
                    maxHeight: '200px',
                    overflowY: 'auto',
                    scrollbarWidth: 'none',
                  }}
                >
                  {isArray(userState?.departments) &&
                    userState?.departments?.map((item: any) => {
                      return (
                        <Box
                          key={item?.id}
                          sx={{
                            width: '100%',
                            '&:hover': {
                              bgcolor: AppColors.light.lightBlue1,
                              cursor: 'pointer',
                            },
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                          onClick={() => onChange(item?.name)}
                        >
                          <TextTooltip
                            sxDisplay={{ padding: 0 }}
                            maxWidth="190px"
                            content={item?.name}
                            display={item?.name}
                          />
                        </Box>
                      );
                    })}
                </Box>
              </Button>
            </Popover>
          </Box>

          <Box
            sx={{ width: '2px', height: '30px', border: '1px solid #E0E0E0' }}
          />
          <Button
            sx={{
              ml: 2,
              width: '200px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            aria-describedby={idAccount}
            onClick={(event) => handleClickPopover(event, setAnchorEl)}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: ' center',
                gap: '4px',
                alignItems: 'center',
              }}
            >
              <AccountIcon />
              <Typography
                sx={{
                  color: AppColors.light.black,
                  maxWidth: '120px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                  lineHeight: '23px',
                  textTransform: 'none',
                }}
              >
                {userState?.userCurrent?.fullName}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DropdownIcon />
            </Box>
          </Button>
          <Popover
            id={idAccount}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Button onClick={logout}>
              <Box
                sx={{
                  width: '190px',
                  display: 'flex',
                  color: AppColors.light.black,
                  p: '0 6px',
                  fontWeight: Fonts.REGULAR,
                }}
              >
                {messages['header.logout'] as string}
              </Box>
            </Button>
          </Popover>
        </Box>
      </Box>
    </AppBar>
  );
};
export default AppHeader;
