import AppNotificationDialog from '@crema/components/AppNotificationDialog';
import { AppColors } from '@crema/constants/AppColors';
import { Fonts } from '@crema/constants/AppEnums';
import { IMPORT_STATUS } from '@crema/constants/components/CustomerConst';
import { useAppStateContext } from '@crema/context/AppContextProvider/StateContextProvider';
import IntlMessages from '@crema/helpers/IntlMessages';
import { MESSAGE } from '@crema/helpers/MessageList';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const CSVImport = () => {
  const id = localStorage.getItem('id_upload');
  const { messages, formatMessage } = useIntl();
  const { appState, setAppState } = useAppStateContext();
  const [statisticDialog, setStatisticDialog] = useState({
    isOpen: false,
    title: '',
    textSuccess: '',
    textFail: '',
  });

  useEffect(() => {
    if (id) {
      let attempts = 0;
      const maxAttempts = 10;
      const intervalId = setInterval(() => {
        attempts++;
        if (attempts > maxAttempts) {
          clearInterval(intervalId);
          localStorage.removeItem('id_upload');
          setAppState({
            ...appState,
            msg: MESSAGE.MSG029,
            type: 'error',
          });
          return;
        }
        jwtAxios.get(`/partners/import/${id}`).then((response: any) => {
          const { data } = response;
          if (
            data.status === IMPORT_STATUS.COMPLETED ||
            data.status === IMPORT_STATUS.FAIL
          ) {
            localStorage.removeItem('id_upload');
            if (data?.errorFileUrl === 'MSG_029') {
              setAppState({
                ...appState,
                msg: MESSAGE.MSG029,
                type: 'error',
              });
            } else {
              setStatisticDialog({
                isOpen: true,
                title: messages['file.import.complete'] as string,
                textSuccess: formatMessage(
                  { id: 'file.import.total.success' },
                  { number: data.numberItemSuccess || 0 },
                ) as string,
                textFail: formatMessage(
                  { id: 'file.import.total.fail' },
                  { number: data.numberItemFail || 0 },
                ) as string,
              });
            }
            clearInterval(intervalId);
          }
        });
      }, 10000);
    }
  }, [id]);

  return (
    <>
      <AppNotificationDialog
        open={statisticDialog.isOpen}
        onDeny={() => setStatisticDialog({ ...statisticDialog, isOpen: false })}
      >
        <Box>
          <Box
            sx={{
              height: '44px',
              bgcolor: AppColors.light.black,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              borderRadius: '10px',
            }}
          >
            <Typography
              sx={{
                color: AppColors.light.white,
                fontSize: '16px',
                fontWeight: Fonts.BOLD,
                padding: '10px',
              }}
            >
              {statisticDialog.title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
              py: '40px',
              textAlign: 'center',
            }}
          >
            <Typography sx={{ fontSize: '16px' }}>
              {statisticDialog.textSuccess}
            </Typography>
            <Typography sx={{ fontSize: '16px' }}>
              {statisticDialog.textFail}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{
                fontWeight: Fonts.BOLD,
                bgcolor: AppColors.light.lightGray,
                color: AppColors.light.black,
                minWidth: '120px',
              }}
              onClick={() =>
                setStatisticDialog({ ...statisticDialog, isOpen: false })
              }
            >
              <IntlMessages id="common.close" />
            </Button>
          </Box>
        </Box>
      </AppNotificationDialog>
    </>
  );
};

export default CSVImport;
