import { Box } from '@mui/material';
import React from 'react';

import Logo from '../../../../../assets/icon/logo.svg';

const AppLogo: React.FC = () => {
  return (
    <Box
      sx={{
        height: '32px',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
          height: '100%',
        },
      }}
    >
      <img src={Logo} alt="Logo" />
    </Box>
  );
};

export default AppLogo;
