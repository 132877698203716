import type { SVGProps } from 'react';

export const ClaimIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9398 4.9387L9.71984 1.7187C9.47317 1.47203 9.13317 1.33203 8.77984 1.33203H3.99984C3.2665 1.33203 2.67317 1.93203 2.67317 2.66536L2.6665 13.332C2.6665 14.0654 3.25984 14.6654 3.99317 14.6654H11.9998C12.7332 14.6654 13.3332 14.0654 13.3332 13.332V5.88536C13.3332 5.53203 13.1932 5.19203 12.9398 4.9387ZM9.8665 9.9987H8.6665V11.9987C8.6665 12.3654 8.3665 12.6654 7.99984 12.6654C7.63317 12.6654 7.33317 12.3654 7.33317 11.9987V9.9987H6.13984C5.83984 9.9987 5.69317 9.6387 5.9065 9.43203L7.77317 7.57203C7.9065 7.44536 8.11317 7.44536 8.2465 7.57203L10.1065 9.43203C10.3065 9.6387 10.1598 9.9987 9.8665 9.9987ZM9.33317 5.9987C8.9665 5.9987 8.6665 5.6987 8.6665 5.33203V2.33203L12.3332 5.9987H9.33317Z"
        fill="#454545"
      />
    </svg>
  );
};
