import { Fonts } from '@crema/constants/AppEnums';
import IntlMessages from '@crema/helpers/IntlMessages';
import { Button, FormControl, alpha } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SxProps, Theme, styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';

import { IconPlus, ResetIcon } from '../../../assets/icon';
import { TextTooltip } from '../Material_v5';

const SelectBox = styled(Select)(({ theme, sx }) => {
  return {
    sx,
    cursor: 'pointer',
    fontFamily: 'NotoSansJP',
    fontSize: '14px',
    height: '42px',
    border: '1px solid transparent',
    borderRadius: '4px',
    borderImageSource: `linear-gradient(0deg, #FFFFFF, #FFFFFF),
                              linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
    borderImageSlice: 1,
    minWidth: 150,
    padding: '14px',
    '& .MuiSelect-select': {
      paddingLeft: 5,
      color: 'text.secondary',
    },
    '& .MuiSelect-icon': {
      color: 'text.secondary',
    },
    '& .MuiOutlinedInput-notchedOutline': {},
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {},
    },
    '&.Mui-focused': {
      backgroundColor: alpha(theme.palette.common.black, 0.03),
      '& .MuiOutlinedInput-notchedOutline': {},
    },
  };
});

type AppSelectOutlineProps = {
  menus: any[];
  onChange: (e: any) => void;
  defaultValue?: any;
  selectionKey?: string;
  sx?: SxProps<Theme>;
  error?: boolean;
  disabled?: boolean;
  pathCreate?: string;
  onReloadList?: () => void;
  isShowMoreBtn?: boolean;
  maxWidth?: string;
  disabledOptions?: Array<number>;
  onReset?: (callback: () => void) => void;
};

const AppSelectBox: React.FC<AppSelectOutlineProps> = ({
  menus = [],
  onChange,
  defaultValue = '',
  selectionKey = '',
  sx = {},
  error = false,
  disabled = false,
  pathCreate = '',
  onReloadList = () => {},
  isShowMoreBtn = false,
  maxWidth = '290px',
  disabledOptions = [],
  onReset,
}) => {
  const [selectionType, setSelectionType] = useState<any>(defaultValue);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleReset = () => {
    setSelectionType('');
  };

  useEffect(() => {
    if (onReset) {
      onReset(handleReset);
    }
  }, [onReset]);

  const handleSelectionType = (value: string) => {
    setSelectionType(value);
    onChange(value);
  };
  const handleScrollToTop = () => {
    if (menuRef.current) {
      menuRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setSelectionType(defaultValue);
  }, [defaultValue]);

  return (
    <FormControl fullWidth error={error} disabled={disabled}>
      <SelectBox
        defaultValue={defaultValue}
        value={selectionType || ''}
        onChange={(event) => handleSelectionType(event.target.value as string)}
        className="select-box"
        sx={sx}
        MenuProps={{
          PaperProps: {
            ref: menuRef,
          },
        }}
      >
        {menus.map((menu, index) => (
          <MenuItem
            key={index}
            value={menu.value}
            sx={{ width: '100%' }}
            disabled={disabledOptions.includes(menu.id)}
          >
            <TextTooltip
              sxDisplay={{ padding: 0 }}
              maxWidth={maxWidth}
              content={selectionKey ? menu[selectionKey] : menu}
              display={selectionKey ? menu[selectionKey] : menu}
            />
          </MenuItem>
        ))}
        {isShowMoreBtn && (
          <MenuItem value={'btn-create'}>
            <Button
              sx={{
                margin: '0 auto',
                width: '100%',
                fontWeight: Fonts.REGULAR,
              }}
              color="primary"
              variant="contained"
              onClick={(event) => {
                event.stopPropagation();
                window.open(pathCreate, '_blank', 'noopener,noreferrer');
              }}
            >
              <IconPlus fill={'white'} />
              <IntlMessages id="common.create.new" />
            </Button>
          </MenuItem>
        )}
        {isShowMoreBtn && (
          <MenuItem value={'btn-reload'}>
            <Button
              sx={{
                margin: '0 auto',
                width: '100%',
                color: '#454545',
                background: '#d6d3d3',
                height: '36.5px',
                fontWeight: Fonts.REGULAR,
              }}
              onClick={(event) => {
                event.stopPropagation();
                onReloadList();
                handleScrollToTop();
              }}
            >
              <ResetIcon />
              <IntlMessages id="common.reload" />
            </Button>
          </MenuItem>
        )}
      </SelectBox>
    </FormControl>
  );
};

export default AppSelectBox;
