/// Load Error Handling
/// refer link: https://vite.dev/guide/build#load-error-handling
/// Step need to migrate after
window.addEventListener('vite:preloadError', (event: any) => {
  console.error('Vite preload error detected:', event?.payload);
  event.preventDefault();
  // window.location.reload();
});


import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
