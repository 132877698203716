import { AppColors } from '@crema/constants/AppColors';
import { styled } from '@mui/material/styles';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const StyledDatePicker = styled(DatePicker)({
  '& .MuiInputBase-root': {
    cursor: 'pointer',
    fontFamily: 'NotoSansJP',
    fontSize: '14px',
    height: '42px !important',
    border: '1px solid transparent',
    borderRadius: '4px',
  },
  '& .MuiInputBase-input': {
    padding: '10px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#0000004D !important',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: AppColors.light.blue01 + '!important',
  },
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: 'red !important',
  },
  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    background: '#eeeeee !important',
    opacity: 0.7,
  },
});

type DatePickerType = DatePickerProps<unknown> &
  React.RefAttributes<HTMLDivElement> & {
    error?: boolean;
  };

const CustomDatePicker = ({ error, ...rest }: DatePickerType) => {
  const value = rest?.value ? dayjs(rest?.value as any) : null;
  return (
    <StyledDatePicker
      {...rest}
      value={value}
      format="YYYY/MM/DD"
      slotProps={{
        textField: {
          variant: 'outlined',
          fullWidth: true,
          error,
        },
      }}
    />
  );
};

export default CustomDatePicker;
