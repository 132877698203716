import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

type DefaultLayoutWrapperProps = {
  children: ReactNode;
  [x: string]: any;
};

const DefaultLayoutWrapper: React.FC<DefaultLayoutWrapperProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        backgroundColor: (theme) => theme.palette.background.default,

        '&.appMainFixedFooter': {
          pb: { xs: 12, xl: 14.5 },
        },
        '&.appMainFixedHeader': {
          pt: 10,
          '& .app-bar': {
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 100,
            width: '100vw !important',
          },
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default DefaultLayoutWrapper;
