import type { SVGProps } from 'react';

export const DropdownIcon = ({
  width = 24,
  height = 24,
  color = '#454545',
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.70956 11.71L11.2996 14.3C11.6896 14.69 12.3196 14.69 12.7096 14.3L15.2996 11.71C15.9296 11.08 15.4796 10 14.5896 10H9.40956C8.51956 10 8.07956 11.08 8.70956 11.71Z"
        fill={color}
      />
    </svg>
  );
};
