import type { SVGProps } from 'react';

export const QuotationIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00006 3.33464V3.58797C5.44673 3.36797 4.85339 3.25464 4.26006 3.25464C3.31339 3.25464 2.36673 3.5413 1.56006 4.11464C1.22006 4.35464 1.18006 4.8413 1.47339 5.13464L3.18673 6.84797H3.92673V7.58797C4.50006 8.1613 5.24673 8.4613 6.00006 8.49464V10.0013H4.66673C4.30006 10.0013 4.00006 10.3013 4.00006 10.668V12.0013C4.00006 12.7346 4.60006 13.3346 5.33339 13.3346H12.0001C13.1067 13.3346 14.0001 12.4413 14.0001 11.3346V3.33464C14.0001 2.96797 13.7001 2.66797 13.3334 2.66797H6.66673C6.30006 2.66797 6.00006 2.96797 6.00006 3.33464ZM5.26006 6.9413V5.50797H3.74006L3.04673 4.81464C3.42673 4.66797 3.84006 4.58797 4.26006 4.58797C5.15339 4.58797 5.98673 4.93464 6.62006 5.5613L7.56006 6.5013L7.42673 6.63463C7.08673 6.97464 6.63339 7.16797 6.14673 7.16797C5.83339 7.16797 5.52673 7.08797 5.26006 6.9413ZM12.6667 11.3346C12.6667 11.7013 12.3667 12.0013 12.0001 12.0013C11.6334 12.0013 11.3334 11.7013 11.3334 11.3346V10.668C11.3334 10.3013 11.0334 10.0013 10.6667 10.0013H7.33339V8.27463C7.71339 8.1213 8.06673 7.89464 8.37339 7.58797L8.50673 7.45464L10.3934 9.33463H11.3334V8.39464L7.33339 4.41464V4.0013H12.6667V11.3346Z"
        fill="#454545"
      />
    </svg>
  );
};
