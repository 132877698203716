import { Routes } from '@crema/constants/Routes';
import { Navigate } from 'react-router-dom';

import Error403 from '../../../modules/errorPages/Error403';
import { appRoutesConfig } from './AppRoutes';
import { authRouteConfig } from './AuthRoutes';
import { errorPagesConfigs } from './ErrorPagesRoutes';

const authorizedStructure = (loginUrl: string) => {
  return {
    fallbackPath: loginUrl,
    unAuthorizedComponent: <Error403 />,
    routes: [...appRoutesConfig],
  };
};

const publicStructure = (initialUrl: string) => {
  return {
    fallbackPath: initialUrl,
    routes: authRouteConfig,
  };
};
const anonymousStructure = (initialUrl: string) => {
  return {
    routes: errorPagesConfigs.concat([
      {
        path: '/',
        element: <Navigate to={initialUrl} />,
      },
      {
        path: '*',
        element: <Navigate to={Routes.error404} />,
      },
    ]),
  };
};

export { authorizedStructure, publicStructure, anonymousStructure };
