import { useAppStateContext } from '@crema/context/AppContextProvider/StateContextProvider';
import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

type MainContentProps = {
  children: ReactNode;

  [x: string]: any;
};

const MainContent: React.FC<MainContentProps> = ({ children, ...rest }) => {
  const { appState } = useAppStateContext();
  const drawerWidth = appState.hiddenDrawer ? '52px' : '150px';

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        ml: { lg: drawerWidth },
        width: { xs: '100%', lg: `calc(100% - ${drawerWidth})` },
        flexDirection: 'column',
        position: 'relative',
        transition: 'all 0.5s ease',
      }}
      className="mainContent"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default MainContent;
