import type { SVGProps } from 'react';

export const Caseicon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 2.0013H9.88C9.6 1.22797 8.86667 0.667969 8 0.667969C7.13333 0.667969 6.4 1.22797 6.12 2.0013H3.33333C2.6 2.0013 2 2.6013 2 3.33464V12.668C2 13.4013 2.6 14.0013 3.33333 14.0013H12.6667C13.4 14.0013 14 13.4013 14 12.668V3.33464C14 2.6013 13.4 2.0013 12.6667 2.0013ZM8 1.83464C8.27333 1.83464 8.5 2.0613 8.5 2.33464C8.5 2.60797 8.27333 2.83464 8 2.83464C7.72667 2.83464 7.5 2.60797 7.5 2.33464C7.5 2.0613 7.72667 1.83464 8 1.83464ZM5.93333 11.3346H5C4.81333 11.3346 4.66667 11.188 4.66667 11.0013V10.048C4.66667 9.9613 4.7 9.87464 4.76667 9.81464L8.64 5.9413L10.0533 7.35463L6.16667 11.2413C6.10667 11.3013 6.02 11.3346 5.93333 11.3346ZM11.2333 6.1813L10.5267 6.88797L9.11333 5.47464L9.82 4.76797C9.95333 4.63464 10.16 4.63464 10.2933 4.76797L11.2333 5.70797C11.3667 5.8413 11.3667 6.04797 11.2333 6.1813Z"
        fill="#454545"
      />
    </svg>
  );
};
