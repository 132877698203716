import type { SVGProps } from 'react';

export const ApprovalIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9398 4.9387L9.71984 1.7187C9.47317 1.47203 9.13317 1.33203 8.77984 1.33203H3.99984C3.2665 1.33203 2.67317 1.93203 2.67317 2.66536L2.6665 13.332C2.6665 14.0654 3.25984 14.6654 3.99317 14.6654H11.9998C12.7332 14.6654 13.3332 14.0654 13.3332 13.332V5.88536C13.3332 5.53203 13.1932 5.19203 12.9398 4.9387ZM6.81984 11.5254L5.4065 10.112C5.1465 9.85203 5.1465 9.43203 5.4065 9.17203C5.6665 8.91203 6.0865 8.91203 6.3465 9.17203L7.2865 10.112L9.6465 7.75203C9.9065 7.49203 10.3265 7.49203 10.5865 7.75203C10.8465 8.01203 10.8465 8.43203 10.5865 8.69203L7.75984 11.5187C7.5065 11.7854 7.07984 11.7854 6.81984 11.5254ZM9.33317 5.9987C8.9665 5.9987 8.6665 5.6987 8.6665 5.33203V2.33203L12.3332 5.9987H9.33317Z"
        fill="#454545"
      />
    </svg>
  );
};
