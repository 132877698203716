import { EMPTY_VALUE } from '@crema/constants/AppString';
import { TableCellSx, TableTitleCellSx } from '@crema/constants/AppStyles';
import { TableCell, TableCellProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

import AppMoney from '../AppMoney';

type Props = {
  isTittle?: boolean;
  label?: string;
  isCurrency?: boolean;
  maxWidth?: string;
};

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  '&:first-of-type': {
    paddingLeft: 20,
  },
  '&:last-of-type': {
    paddingRight: 20,
  },
}));

const AppTableCell: FC<Props & TableCellProps> = (props) => {
  const {
    isTittle = false,
    label,
    isCurrency = false,
    maxWidth,
    ...rest
  } = props;

  if (isCurrency) {
    return (
      <StyledTableCell {...rest}>
        {label ? (
          <AppMoney amount={parseInt(label)} variant="small" />
        ) : (
          <Typography sx={TableCellSx}>{EMPTY_VALUE}</Typography>
        )}
      </StyledTableCell>
    );
  }

  const styleLabel = isTittle ? TableTitleCellSx : TableCellSx;

  return (
    <StyledTableCell
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth,
        width: '100%',
      }}
      {...rest}
    >
      <Typography
        sx={{
          ...styleLabel,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          whiteSpace: 'pre',
        }}
      >
        {label || EMPTY_VALUE}
      </Typography>
    </StyledTableCell>
  );
};

export default AppTableCell;
