import type { SVGProps } from 'react';

export const ContactIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_631_20350)">
        <path
          d="M8.77984 1.33203H3.99984C3.2665 1.33203 2.6665 1.93203 2.6665 2.66536V13.332C2.6665 14.0654 3.2665 14.6654 3.99984 14.6654H11.9998C12.7332 14.6654 13.3332 14.0654 13.3332 13.332V5.88536C13.3332 5.53203 13.1932 5.19203 12.9398 4.94536L9.71984 1.72536C9.47317 1.47203 9.13317 1.33203 8.77984 1.33203ZM10.6665 9.9987C10.6665 11.5587 9.3265 12.8054 7.73984 12.652C6.35317 12.5187 5.33317 11.2787 5.33317 9.88536V6.42536C5.33317 5.55203 5.95984 4.7587 6.8265 4.67203C7.8265 4.57203 8.6665 5.35203 8.6665 6.33203V9.33203C8.6665 9.6987 8.3665 9.9987 7.99984 9.9987C7.63317 9.9987 7.33317 9.6987 7.33317 9.33203V6.33203C7.33317 6.14536 7.1865 5.9987 6.99984 5.9987C6.81317 5.9987 6.6665 6.14536 6.6665 6.33203V9.92537C6.6665 10.592 7.11984 11.2054 7.77317 11.312C8.61317 11.452 9.33317 10.8054 9.33317 9.9987V7.9987C9.33317 7.63203 9.63317 7.33203 9.99984 7.33203C10.3665 7.33203 10.6665 7.63203 10.6665 7.9987V9.9987ZM9.33317 4.66536V2.66536L11.9998 5.33203H9.99984C9.63317 5.33203 9.33317 5.03203 9.33317 4.66536Z"
          fill="#454545"
        />
      </g>
      <defs>
        <clipPath id="clip0_631_20350">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
