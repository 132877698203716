import { EMPTY_VALUE } from '@crema/constants/AppString';
import { SxProps, Theme, Tooltip, Typography, styled } from '@mui/material';
import { FC, ReactNode } from 'react';

type Props = {
  content: string;
  display: string;
  sxDisplay?: SxProps<Theme>;
  maxWidth?: string;
};

interface CustomTooltipProps {
  className?: string;
  title: ReactNode;
  children: any;
}

const CustomTooltip = styled(
  ({ className, title, children, ...props }: CustomTooltipProps) => (
    <Tooltip
      title={title}
      classes={{ tooltip: className }}
      {...props}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      {children}
    </Tooltip>
  ),
)({
  tooltip: {
    backgroundColor: '#555',
    color: '#fff',
    fontSize: '9px',
    fontFamily: 'NotoSansJP',
    borderRadius: '4px',
  },
});

const TextTooltip: FC<Props> = ({
  content,
  display,
  sxDisplay,
  maxWidth = '100px',
}) => {
  return (
    <CustomTooltip title={content}>
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'pre',
          padding: '0 10px',
          maxWidth: maxWidth,
          ...sxDisplay,
        }}
      >
        {display || EMPTY_VALUE}
      </Typography>
    </CustomTooltip>
  );
};

export default TextTooltip;
