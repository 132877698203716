import type { SVGProps } from 'react';

export const SettingIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9998 7.9987C12.9998 7.84536 12.9931 7.6987 12.9798 7.54536L14.2198 6.60536C14.4864 6.40536 14.5598 6.03203 14.3931 5.7387L13.1464 3.58536C12.9798 3.29203 12.6198 3.17203 12.3131 3.30536L10.8798 3.91203C10.6331 3.7387 10.3731 3.58536 10.0998 3.4587L9.90644 1.9187C9.86644 1.58536 9.57978 1.33203 9.24644 1.33203H6.75978C6.41978 1.33203 6.13311 1.58536 6.09311 1.9187L5.89978 3.4587C5.62644 3.58536 5.36644 3.7387 5.11978 3.91203L3.68644 3.30536C3.37978 3.17203 3.01978 3.29203 2.85311 3.58536L1.60644 5.74536C1.43978 6.0387 1.51311 6.40536 1.77978 6.61203L3.01978 7.55203C3.00644 7.6987 2.99978 7.84536 2.99978 7.9987C2.99978 8.15203 3.00644 8.2987 3.01978 8.45203L1.77978 9.39203C1.51311 9.59203 1.43978 9.96537 1.60644 10.2587L2.85311 12.412C3.01978 12.7054 3.37978 12.8254 3.68644 12.692L5.11978 12.0854C5.36644 12.2587 5.62644 12.412 5.89978 12.5387L6.09311 14.0787C6.13311 14.412 6.41978 14.6654 6.75311 14.6654H9.23978C9.57311 14.6654 9.85978 14.412 9.89978 14.0787L10.0931 12.5387C10.3664 12.412 10.6264 12.2587 10.8731 12.0854L12.3064 12.692C12.6131 12.8254 12.9731 12.7054 13.1398 12.412L14.3864 10.2587C14.5531 9.96537 14.4798 9.5987 14.2131 9.39203L12.9731 8.45203C12.9931 8.2987 12.9998 8.15203 12.9998 7.9987ZM8.02644 10.332C6.73978 10.332 5.69311 9.28536 5.69311 7.9987C5.69311 6.71203 6.73978 5.66536 8.02644 5.66536C9.31311 5.66536 10.3598 6.71203 10.3598 7.9987C10.3598 9.28536 9.31311 10.332 8.02644 10.332Z"
        fill="#454545"
      />
    </svg>
  );
};
