import { Fonts } from '@crema/constants/AppEnums';
import IntlMessages from '@crema/helpers/IntlMessages';
import { checkPermission } from '@crema/helpers/RouteHelper';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import { RouterConfigData } from '@crema/types/models/Apps';
import { Icon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import React, { useMemo } from 'react';

import AppBadge from '../../../../AppBadge';
import AppNavLink from '../../../../AppNavLink';
import VerticalNavItem from './VerticalNavItem';

type VerticalItemProps = {
  item: RouterConfigData;
  level: number;
};

const VerticalItem: React.FC<VerticalItemProps> = ({ level, item }) => {
  const { user } = useAuthUser();
  const hasPermission = useMemo(
    () => checkPermission(item.permittedRole, user.role),
    [item.permittedRole, user.role],
  );
  if (!hasPermission) {
    return null;
  }

  return (
    <VerticalNavItem
      level={level}
      button
      component={AppNavLink}
      to={item.url}
      activeClassName="active"
      exact={item.exact}
    >
      {item.icon && (
        <Box component="span">
          <Icon
            sx={{
              fontSize: 18,
              display: 'block',
              mr: '4px',
              color: '#454545',
            }}
            className={clsx('nav-item-icon', 'material-icons-outlined')}
            color="action"
          >
            {item.icon}
          </Icon>
        </Box>
      )}
      <Typography
        className="nav-item-content nav-item-text"
        sx={{
          fontWeight: level && Fonts.REGULAR + '!important',
          color: '#454545',
        }}
      >
        <IntlMessages id={item.messageId} />
      </Typography>
      {item.count && (
        <Box sx={{ mr: 3.5 }} className="menu-badge">
          <AppBadge count={item.count} color={item.color} />
        </Box>
      )}
    </VerticalNavItem>
  );
};

export default React.memo(VerticalItem);
