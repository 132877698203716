import { AppColors } from '@crema/constants/AppColors';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  height: '40px',
  minHeight: '40px',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 4,
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: AppColors.light.blue01,
    zIndex: 2,
    bottom: 0,
  },
  '& .MuiTabs-scroller': {
    paddingBottom: '3px',
    marginTop: '7px',
  },
});

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontSize: theme.typography.pxToRem(14),
  fontFamily: 'NotoSansJP',
  minWidth: 92,
  height: '26px',
  minHeight: '26px',
  color: '#2C9EDF',
  backgroundColor: '#FFF',
  border: 'solid 1px #2C9EDF',
  borderRadius: '4px',
  marginRight: '10px',
  '&.Mui-selected': {
    color: '#FFF',
    backgroundColor: '#2C9EDF',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

type TabCustomProps = {
  className?: string;
  value: number;
  onChange: (event: any, newValue: number) => void;
  options: Array<string>;
  isList?: boolean;
};

const TabCustom: FC<TabCustomProps> = ({
  options,
  className,
  value,
  onChange,
  isList = false,
}) => {
  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        justifyContent: 'end',
        borderBottom: isList ? '4px solid #00000014' : 'none',
        zIndex: 0,
      }}
    >
      <StyledTabs
        value={value}
        onChange={onChange}
        aria-label="styled tabs"
        sx={isList ? { position: 'absolute', top: '-36px' } : {}}
      >
        {options.map((item, index) => (
          <StyledTab key={index} label={item} />
        ))}
      </StyledTabs>
    </Box>
  );
};

export default TabCustom;
