import type { SVGProps } from 'react';

export const ShowMenuIcon = ({
  width = 32,
  height = 32,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#2C9EDF" />
      <path
        d="M23.0005 14.9997H11.8305L16.7105 10.1197C17.1005 9.72973 17.1005 9.08973 16.7105 8.69973C16.3205 8.30973 15.6905 8.30973 15.3005 8.69973L8.71047 15.2897C8.32047 15.6797 8.32047 16.3097 8.71047 16.6997L15.3005 23.2897C15.6905 23.6797 16.3205 23.6797 16.7105 23.2897C17.1005 22.8997 17.1005 22.2697 16.7105 21.8797L11.8305 16.9997H23.0005C23.5505 16.9997 24.0005 16.5497 24.0005 15.9997C24.0005 15.4497 23.5505 14.9997 23.0005 14.9997Z"
        fill="white"
      />
    </svg>
  );
};
