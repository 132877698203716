import { TableCell, styled } from '@mui/material';

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  '&:first-of-type': {
    paddingLeft: 20,
  },
  '&:last-of-type': {
    paddingRight: 20,
  },
  whiteSpace: 'pre',
}));

export default StyledTableCell;
