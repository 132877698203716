import { AppColors } from '@crema/constants/AppColors';
import { isValidDate } from '@crema/helpers/ValidationSchema';
import { styled } from '@mui/material/styles';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const StyledDatePicker = styled(DatePicker)<{ error?: boolean }>(
  ({ error }) => ({
    '& .MuiInputBase-root': {
      cursor: 'pointer',
      fontFamily: 'NotoSansJP',
      fontSize: '14px',
      height: '42px !important',
      border: '1px solid transparent',
      borderRadius: '4px',
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: error ? 'red !important' : '#0000004D !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: AppColors.light.blue01 + '!important',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      background: '#eeeeee !important',
      opacity: 0.7,
    },
    '&:hover .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0000004D !important',
    },
  }),
);

type DatePickerType = DatePickerProps<unknown> &
  React.RefAttributes<HTMLDivElement>;

interface CustomDatePickerProps extends DatePickerType {
  error?: boolean;
}

const CustomDatePicker = (props: CustomDatePickerProps) => {
  const value = props?.value ? dayjs(props?.value as any) : null;
  return (
    <StyledDatePicker
      {...props}
      value={value}
      format="YYYY/MM/DD"
      slotProps={{
        textField: {
          variant: 'outlined',
          fullWidth: true,
          error: props.error,
        } as any,
      }}
      error={props.error ? props.error : !isValidDate(props?.value as any)}
    />
  );
};

export default CustomDatePicker;
