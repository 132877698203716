import type { SVGProps } from 'react';

export const DeliveryIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9998 5.33464H11.3332V4.0013C11.3332 3.26797 10.7332 2.66797 9.99984 2.66797H1.99984C1.2665 2.66797 0.666504 3.26797 0.666504 4.0013V10.0013C0.666504 10.7346 1.2665 11.3346 1.99984 11.3346C1.99984 12.4413 2.89317 13.3346 3.99984 13.3346C5.1065 13.3346 5.99984 12.4413 5.99984 11.3346H9.99984C9.99984 12.4413 10.8932 13.3346 11.9998 13.3346C13.1065 13.3346 13.9998 12.4413 13.9998 11.3346H14.6665C15.0332 11.3346 15.3332 11.0346 15.3332 10.668V8.44797C15.3332 8.1613 15.2398 7.8813 15.0665 7.64797L13.5332 5.6013C13.4065 5.43463 13.2065 5.33464 12.9998 5.33464ZM3.99984 12.0013C3.63317 12.0013 3.33317 11.7013 3.33317 11.3346C3.33317 10.968 3.63317 10.668 3.99984 10.668C4.3665 10.668 4.6665 10.968 4.6665 11.3346C4.6665 11.7013 4.3665 12.0013 3.99984 12.0013ZM12.9998 6.33464L14.3065 8.0013H11.3332V6.33464H12.9998ZM11.9998 12.0013C11.6332 12.0013 11.3332 11.7013 11.3332 11.3346C11.3332 10.968 11.6332 10.668 11.9998 10.668C12.3665 10.668 12.6665 10.968 12.6665 11.3346C12.6665 11.7013 12.3665 12.0013 11.9998 12.0013Z"
        fill="#454545"
      />
    </svg>
  );
};
