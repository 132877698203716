import { Fonts } from '@crema/constants/AppEnums';
import { useSidebarContext } from '@crema/context/AppContextProvider/SidebarContextProvider';
import { useThemeContext } from '@crema/context/AppContextProvider/ThemeContextProvider';
import IntlMessages from '@crema/helpers/IntlMessages';
import { checkPermission } from '@crema/helpers/RouteHelper';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import { RouterConfigData } from '@crema/types/models/Apps';
import { Collapse, Icon, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import VerticalItemSpecial from '../VerticalItemSpecial';
import VerticalCollapseItem from './VerticalCollapseItem';

const needsToBeOpened = (pathname: string, item: RouterConfigData): boolean => {
  return !!(pathname && isUrlInChildren(item, pathname));
};

const isUrlInChildren = (parent: RouterConfigData, url: string): boolean => {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i++) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (
      parent.children[i].url === url ||
      url.includes(parent.children[i].url || '')
    ) {
      return true;
    }
  }

  return false;
};

type VerticalCollapseProps = {
  item: RouterConfigData;
  level: number;
};

const VerticalCollapse: React.FC<VerticalCollapseProps> = ({ item, level }) => {
  const { theme } = useThemeContext();
  const { sidebarTextColor } = useSidebarContext();
  const { pathname } = useLocation();
  const [open, setOpen] = useState<boolean>(() =>
    needsToBeOpened(pathname, item),
  );

  useEffect(() => {
    if (needsToBeOpened(pathname, item)) {
      setOpen(true);
    }
  }, [pathname, item]);

  const handleClick = () => {
    setOpen(!open);
  };

  const { user } = useAuthUser();
  const hasPermission = useMemo(
    () => checkPermission(item.permittedRole, user.role),
    [item.permittedRole, user.role],
  );

  if (!hasPermission) {
    return null;
  }

  return (
    <>
      <VerticalCollapseItem
        level={level}
        sidebarTextColor={sidebarTextColor}
        button
        component="div"
        className={clsx('menu-vertical-collapse', open && 'open')}
        onClick={handleClick}
        sx={{ px: '4px' }}
      >
        {item.icon && (
          <Box component="span">
            <Icon
              sx={{ mr: '4px' }}
              color="action"
              className={clsx('nav-item-icon')}
            >
              {item.icon}
            </Icon>
          </Box>
        )}
        <Typography
          className="nav-item-content"
          sx={{
            color: '#454545',
            fontWeight: Fonts.BOLD + '!important',
            fontSize: 16,
            width: '100%',
          }}
        >
          <IntlMessages id={item.messageId} />
        </Typography>
        <IconButton
          className="nav-item-icon-arrow-btn"
          sx={{ p: 0, mr: 0.75 }}
          disableRipple
          size="large"
        >
          <Icon className="nav-item-icon-arrow" color="inherit">
            {open
              ? 'expand_less'
              : theme.direction === 'ltr'
              ? 'expand_more'
              : 'chevron_left'}
          </Icon>
        </IconButton>
      </VerticalCollapseItem>

      {item.children && (
        <Box sx={{ backgroundColor: '#fff' }}>
          <Collapse in={open} className="collapse-children">
            {item.children.map((item) => (
              <Box
                key={item.id}
                sx={{ fontSize: 14, fontWeight: Fonts.MEDIUM }}
              >
                <React.Fragment>
                  {item.type === 'collapse' && (
                    <VerticalCollapse item={item} level={level + 1} />
                  )}

                  {item.type === 'item' && (
                    <VerticalItemSpecial item={item} level={level + 1} />
                  )}
                </React.Fragment>
              </Box>
            ))}
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default React.memo(VerticalCollapse);
