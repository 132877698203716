import type { SVGProps } from 'react';

export const ShareIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4067 2 12.6667 2ZM12.6667 12.6667H3.33333V4.66667H12.6667V12.6667ZM9 8.66667C9 9.22 8.55333 9.66667 8 9.66667C7.44667 9.66667 7 9.22 7 8.66667C7 8.11333 7.44667 7.66667 8 7.66667C8.55333 7.66667 9 8.11333 9 8.66667ZM8 6C6.18 6 4.62667 7.10667 4 8.66667C4.62667 10.2267 6.18 11.3333 8 11.3333C9.82 11.3333 11.3733 10.2267 12 8.66667C11.3733 7.10667 9.82 6 8 6ZM8 10.3333C7.08 10.3333 6.33333 9.58667 6.33333 8.66667C6.33333 7.74667 7.08 7 8 7C8.92 7 9.66667 7.74667 9.66667 8.66667C9.66667 9.58667 8.92 10.3333 8 10.3333Z"
        fill="#454545"
      />
    </svg>
  );
};
