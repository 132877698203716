import { jaJP } from '@mui/material/locale';

import jaMessages from '../locales/ja-JP.json';

const JaLang = {
  messages: {
    ...jaMessages,
  },
  muiLocale: jaJP,
  locale: 'ja_JP',
};
export default JaLang;
