import type { SVGProps } from 'react';

export const PeopleIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6665 7.33203C11.7732 7.33203 12.6598 6.4387 12.6598 5.33203C12.6598 4.22536 11.7732 3.33203 10.6665 3.33203C9.55984 3.33203 8.6665 4.22536 8.6665 5.33203C8.6665 6.4387 9.55984 7.33203 10.6665 7.33203ZM5.33317 7.33203C6.43984 7.33203 7.3265 6.4387 7.3265 5.33203C7.3265 4.22536 6.43984 3.33203 5.33317 3.33203C4.2265 3.33203 3.33317 4.22536 3.33317 5.33203C3.33317 6.4387 4.2265 7.33203 5.33317 7.33203ZM5.33317 8.66536C3.77984 8.66536 0.666504 9.44536 0.666504 10.9987V11.9987C0.666504 12.3654 0.966504 12.6654 1.33317 12.6654H9.33317C9.69984 12.6654 9.99984 12.3654 9.99984 11.9987V10.9987C9.99984 9.44536 6.8865 8.66536 5.33317 8.66536ZM10.6665 8.66536C10.4732 8.66536 10.2532 8.6787 10.0198 8.6987C10.0332 8.70536 10.0398 8.7187 10.0465 8.72536C10.8065 9.2787 11.3332 10.0187 11.3332 10.9987V11.9987C11.3332 12.232 11.2865 12.4587 11.2132 12.6654H14.6665C15.0332 12.6654 15.3332 12.3654 15.3332 11.9987V10.9987C15.3332 9.44536 12.2198 8.66536 10.6665 8.66536Z"
        fill="#454545"
      />
    </svg>
  );
};
