import type { SVGProps } from 'react';

export const AccountingIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9398 4.9387L9.71984 1.7187C9.47317 1.47203 9.13317 1.33203 8.77984 1.33203H3.99984C3.2665 1.33203 2.6665 1.93203 2.6665 2.66536V13.332C2.6665 14.0654 3.2665 14.6654 3.99984 14.6654H11.9998C12.7332 14.6654 13.3332 14.0654 13.3332 13.332V5.88536C13.3332 5.53203 13.1932 5.19203 12.9398 4.9387ZM9.33317 7.9987C9.69984 7.9987 9.99984 8.2987 9.99984 8.66536V10.6654C9.99984 11.032 9.69984 11.332 9.33317 11.332H8.6665C8.6665 11.6987 8.3665 11.9987 7.99984 11.9987C7.63317 11.9987 7.33317 11.6987 7.33317 11.332H6.6665C6.29984 11.332 5.99984 11.032 5.99984 10.6654C5.99984 10.2987 6.29984 9.9987 6.6665 9.9987H8.6665V9.33203H6.6665C6.29984 9.33203 5.99984 9.03203 5.99984 8.66536V6.66536C5.99984 6.2987 6.29984 5.9987 6.6665 5.9987H7.33317C7.33317 5.63203 7.63317 5.33203 7.99984 5.33203C8.3665 5.33203 8.6665 5.63203 8.6665 5.9987H9.33317C9.69984 5.9987 9.99984 6.2987 9.99984 6.66536C9.99984 7.03203 9.69984 7.33203 9.33317 7.33203H7.33317V7.9987H9.33317Z"
        fill="#454545"
      />
    </svg>
  );
};
