import React, { ReactNode } from 'react';

import LayoutContextProvider from './LayoutContextProvider';
import LocaleContextProvider from './LocaleContextProvider';
import SidebarContextProvider from './SidebarContextProvider';
import { AppStateProvider } from './StateContextProvider';
import ThemeContextProvider from './ThemeContextProvider';

interface AppContextProviderProps {
  children: ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  return (
    <AppStateProvider>
      <ThemeContextProvider>
        <LocaleContextProvider>
          <LayoutContextProvider>
            <SidebarContextProvider>{children}</SidebarContextProvider>
          </LayoutContextProvider>
        </LocaleContextProvider>
      </ThemeContextProvider>
    </AppStateProvider>
  );
};

export default AppContextProvider;
