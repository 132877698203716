import { useAuthUser } from '@crema/hooks/AuthHooks';
import React, { ReactNode } from 'react';

import AppLoader from './AppLoader';

type AuthRoutesProps = {
  children: ReactNode;
};

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  const { isLoading } = useAuthUser();
  return isLoading ? <AppLoader /> : <>{children}</>;
};

export default AuthRoutes;
