import type { SVGProps } from 'react';

export const ClosedRoundIcon = ({
  width = 32,
  height = 32,
  color = '#454545',
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.7" cx="16" cy="16" r="16" fill="white" />
      <path
        d="M22.3002 9.71022C22.2077 9.61752 22.0978 9.54397 21.9768 9.49379C21.8559 9.44361 21.7262 9.41778 21.5952 9.41778C21.4643 9.41778 21.3346 9.44361 21.2136 9.49379C21.0926 9.54397 20.9827 9.61752 20.8902 9.71022L16.0002 14.5902L11.1102 9.70022C11.0176 9.60764 10.9077 9.5342 10.7868 9.4841C10.6658 9.43399 10.5362 9.4082 10.4052 9.4082C10.2743 9.4082 10.1446 9.43399 10.0237 9.4841C9.90272 9.5342 9.79281 9.60764 9.70022 9.70022C9.60764 9.79281 9.5342 9.90272 9.4841 10.0237C9.43399 10.1446 9.4082 10.2743 9.4082 10.4052C9.4082 10.5362 9.43399 10.6658 9.4841 10.7868C9.5342 10.9077 9.60764 11.0176 9.70022 11.1102L14.5902 16.0002L9.70022 20.8902C9.60764 20.9828 9.5342 21.0927 9.4841 21.2137C9.43399 21.3346 9.4082 21.4643 9.4082 21.5952C9.4082 21.7262 9.43399 21.8558 9.4841 21.9768C9.5342 22.0977 9.60764 22.2076 9.70022 22.3002C9.79281 22.3928 9.90272 22.4662 10.0237 22.5163C10.1446 22.5665 10.2743 22.5922 10.4052 22.5922C10.5362 22.5922 10.6658 22.5665 10.7868 22.5163C10.9077 22.4662 11.0176 22.3928 11.1102 22.3002L16.0002 17.4102L20.8902 22.3002C20.9828 22.3928 21.0927 22.4662 21.2137 22.5163C21.3346 22.5665 21.4643 22.5922 21.5952 22.5922C21.7262 22.5922 21.8558 22.5665 21.9768 22.5163C22.0977 22.4662 22.2076 22.3928 22.3002 22.3002C22.3928 22.2076 22.4662 22.0977 22.5163 21.9768C22.5665 21.8558 22.5922 21.7262 22.5922 21.5952C22.5922 21.4643 22.5665 21.3346 22.5163 21.2137C22.4662 21.0927 22.3928 20.9828 22.3002 20.8902L17.4102 16.0002L22.3002 11.1102C22.6802 10.7302 22.6802 10.0902 22.3002 9.71022Z"
        fill={color}
      />
    </svg>
  );
};
