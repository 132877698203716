import { AppColors } from '@crema/constants/AppColors';
import { Fonts } from '@crema/constants/AppEnums';
import IntlMessages from '@crema/helpers/IntlMessages';
import { Box, Typography } from '@mui/material';

type AppBannerProps = {
  msg?: string;
  type?: string;
};

const AppBanner = ({ msg, type }: AppBannerProps) => {
  if (!msg) {
    return null;
  }
  return (
    <Box
      sx={{
        bgcolor:
          type === 'error' ? AppColors.light.error : AppColors.light.blue01,
        display: 'flex',
        width: '100%',
        height: '30px',
        position: 'fixed',
        top: 0,
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.5s ease',
      }}
    >
      <Typography
        sx={{
          color: '#FFF',
          fontWeight: Fonts.BOLD,
        }}
      >
        <IntlMessages id={msg} />
      </Typography>
    </Box>
  );
};

export default AppBanner;
