export const Routes = {
  signIn: '/sign-in',
  forget: '/forget-password',
  reset: '/account/reset/finish',

  home: '/home',
  todoList: '/home/todo-list',
  notifications: '/home/notifications',
  notificationsDetail: '/home/notifications/:id',

  customers: '/customers',
  customerUploadCSV: '/customers/upload',
  customerDetail: '/customers/:id',
  customerCreate: '/customers/create',
  customerEdit: '/customers/edit/:id',
  customerDuplicate: '/customers/duplicate/:id',

  situation: '/projects',
  situationDetail: '/projects/:id',
  situationCreate: '/projects/create',
  situationEdit: '/projects/edit/:id',
  situationDuplicate: '/projects/duplicate/:id',

  quotation: '/quotations',
  quotationsDetail: '/quotations/:id',
  quotationCreate: '/quotations/create',
  quotationEdit: '/quotations/edit/:id',
  quotationDuplicate: '/quotations/duplicate/:id',

  order: '/orders',
  orderDetail: '/orders/:id',
  orderCreate: '/orders/create',
  orderEdit: '/orders/edit/:id',
  orderDuplicate: '/orders/duplicate/:id',

  delivery: '/delivery',
  deliveryDetail: '/delivery/:id',
  deliveryCreate: '/delivery/create',
  deliveryEdit: '/delivery/edit/:id',
  deliveryDuplicate: '/delivery/duplicate/:id',

  sale: '/sale',
  saleDetail: '/sale/:id',
  saleCreate: '/sale/create',
  saleEdit: '/sale/edit/:id',
  saleDuplicate: '/sale/duplicate/:id',

  billing: '/billing',
  billingDetail: '/billing/:id',
  billingCreate: '/billing/create/:saleId?',
  billingEdit: '/billing/edit/:billingId?',
  billingDuplicate: '/billing/duplicate/:billingId?',

  application: '/application/home',
  applicationList: '/application/list',
  approvalList: '/approval/list',

  bookkeeping: '/bookkeeping',

  payment: '/payment',
  withdrawal: '/withdrawal',
  journal: '/journal',
  accountingAccount: '/accountingAccount',

  settings: '/settings',

  settingsProduct: '/settings/products',
  settingsProductDetail: '/settings/products/:id',
  settingsProductCreate: '/settings/products/create',
  settingsProductEdit: '/settings/products/edit/:id',
  settingsProductDuplicate: '/settings/products/duplicate/:id',

  settingsDataOffice: '/settings/data/office',

  settingsDataDepartment: '/settings/data/department',
  settingsDataDepartmentDetail: '/settings/data/department/:id',
  settingsDataDepartmentCreate: '/settings/data/department/create',
  settingsDataDepartmentEdit: '/settings/data/department/edit/:id',
  settingsDataDepartmentDuplicate: '/settings/data/department/duplicate/:id',

  settingsDataBankAccount: '/settings/data/bank',
  settingsDataBankAccountDetail: '/settings/data/bank/:id',
  settingsDataBankAccountCreate: '/settings/data/bank/create',
  settingsDataBankAccountEdit: '/settings/data/bank/edit/:id',
  settingsDataBankAccountDuplicate: '/settings/data/bank/duplicate/:id',

  settingsDataNotice: '/settings/data/notice',
  settingsDataNoticeDetail: '/settings/data/notice/:id',
  settingsDataNoticeCreate: '/settings/data/notice/create',
  settingsDataNoticeEdit: '/settings/data/notice/edit/:id',

  settingsMasterAccount: '/settings/master/account',
  settingsMasterAccountDetail: '/settings/master/account/:id',
  settingsMasterAccountCreate: '/settings/master/account/create',
  settingsMasterAccountEdit: '/settings/master/account/edit/:id',
  settingsMasterAccountDuplicate: '/settings/master/account/duplicate/:id',

  error401: '/error-pages/error-401',
  error403: '/error-pages/error-403',
  error404: '/error-pages/error-404',
  error500: '/error-pages/error-500',
  error503: '/error-pages/error-503',
  maintain: '/error-pages/maintenance',

  view: '/view',
  myShare: '/my-share',
  yourShare: '/your-share',
};
