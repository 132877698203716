import FirebaseAuthProvider from '@crema/services/auth/firebase/FirebaseAuthProvider';
import JWTAuthAuthProvider from '@crema/services/auth/jwt-auth/JWTAuthProvider';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const AppAuthProvider = ({ children }: Props) => {
  return (
    <FirebaseAuthProvider>
      <JWTAuthAuthProvider>{children}</JWTAuthAuthProvider>
    </FirebaseAuthProvider>
  );
};

export default AppAuthProvider;
