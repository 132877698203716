import { AppColors } from '@crema/constants/AppColors';
import { Box, Dialog, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactNode } from 'react';

import { ClosedRoundIcon } from '../../../assets/icon';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },

  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type AppConfirmDialogProps = {
  content?: string | ReactNode;
  open: boolean;
  onDeny: (isOpen: boolean) => void;
  children?: ReactNode;
};

const AppNotificationDialog: React.FC<AppConfirmDialogProps> = ({
  open,
  onDeny,
  content,
  children,
}) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={() => onDeny(false)}
      PaperProps={{
        style: {
          overflow: 'visible',
          borderRadius: '10px',
          boxShadow: '0 0 10px 2px gray',
          maxWidth: '1000px',
        },
      }}
    >
      <Box
        position="absolute"
        top="-32px"
        right="-32px"
        color="black"
        onClick={() => onDeny(false)}
        sx={{
          cursor: 'pointer',
          borderRadius: 100,
          boxShadow: '0 0 10px 2px gray',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <ClosedRoundIcon />
      </Box>
      <Box
        sx={{
          minWidth: '400px',
          minHeight: '200px',
          padding: '20px',
          // flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
        id="alert-dialog-description"
      >
        {children ?? (
          <Box
            sx={{
              flex: 1,
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Typography sx={{ color: AppColors.light.black }}>
              {content || ''}
            </Typography>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default AppNotificationDialog;
