import { AppColors } from './AppColors';

export const authRole = {
  Admin: ['admin'],
  User: ['user', 'admin'],
};

export const defaultUser = {
  uid: 'john-alex',
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: '/assets/images/avatar/A11.jpg',
};
export const allowMultiLanguage = import.meta.env.VITE__MULTILINGUAL === 'true';
export const fileStackKey = import.meta.env.VITE__FILESTACK_KEY as string;
export const initialUrl = import.meta.env.VITE__INITIAL_URL as string; // this url will open after login
export const baseApiUrl = import.meta.env.VITE__BASE_API_URL as string;

export const NOT_SPECIFIED = 'NOT_SPECIFIED';
export const PERIOD_SPECIFIED = 'PERIOD_SPECIFIED';
export const DATE_SPECIFIED = 'DATE_SPECIFIED';

export const timePeriodOptions = [
  {
    label: '日',
    value: 'DAY',
  },
  {
    label: '週',
    value: 'WEEK',
  },
  {
    label: 'ヶ月',
    value: 'MONTH',
  },
];

export const OrderStatus = [
  {
    label: '未回答',
    value: 'UNANSWERED',
  },
  {
    label: '受注済',
    value: 'ORDERED',
  },
  {
    label: '失注',
    value: 'ORDER_LOST',
  },
];

export const PAYMENT_METHODS = [
  { label: '振込', value: 'CASH_BANK_TRANSFER' },
  { label: '現金', value: 'CASH' },
  { label: '手形', value: 'BANK_DRAFT' },
  { label: '振替', value: 'BANK_ACCOUNT_TRANSFER' },
];

export const quotationStatusOptions = [
  {
    label: 'common.unanswered',
    value: 'UNANSWERED',
    sxContainer: {
      border: 'solid 2px #0000004D',
      borderRadius: '10px !important',
      bgcolor: AppColors.light.white,
    },
    color: '#0000004D',
  },
  {
    label: 'common.status.ordered',
    value: 'ORDERED',
    sxContainer: {
      border: `solid 2px ${AppColors.light.blue01}`,
      borderRadius: '10px !important',
      bgcolor: AppColors.light.blue01,
    },
    color: AppColors.light.white,
  },
  {
    label: 'common.status.order.lost',
    value: 'ORDER_LOST',
    sxContainer: {
      borderRadius: '10px !important',
      bgcolor: AppColors.light.black,
    },
    color: AppColors.light.white,
  },
];

export const requestStatusOptions = [
  {
    label: 'home.todoList.status.pending',
    value: false,
    sxContainer: {
      border: 'solid 2px #0000004D',
      borderRadius: '10px !important',
      bgcolor: AppColors.light.white,
    },
    color: '#0000004D',
  },
  {
    label: 'common.send',
    value: true,
    sxContainer: {
      border: `solid 2px ${AppColors.light.blue01}`,
      borderRadius: '10px !important',
      bgcolor: AppColors.light.blue01,
    },
    color: AppColors.light.white,
  },
];

export enum UserRole {
  ViewOtherDepartment = 'VIEW_OTHER_DEPARTMENT',
  CreateEditOtherDepartment = 'CREATE_EDIT_OTHER_DEPARTMENT',
  NotViewOtherDepartment = 'NOT_VIEW_OTHER_DEPARTMENT',
  AccessAccount = 'ACCESS_ACCOUNT',
  NotAccessAccount = 'NOT_ACCESS_ACCOUNT',
  AllowApproval = 'ALLOW_APPROVAL',
  NotAllowApproval = 'NOT_ALLOW_APPROVAL',
  AccessAccountingManagement = 'ACCESS_ACCOUNTING_MANAGEMENT',
  NotAccessAccountingManagement = 'NOT_ACCESS_ACCOUNTING_MANAGEMENT',
  CreateVariousSetting = 'CREATE_VARIOUS_SETTING',
  NotCreateVariousSetting = 'NOT_CREATE_VARIOUS_SETTING',
  CreateNotification = 'CREATE_NOTIFICATION',
  NotCreateNotification = 'NOT_CREATE_NOTIFICATION',
}

export const TYPE_FILTER = {
  ALL: 'ALL',
  IN_CHARGE: 'IN_CHARGE',
  CANCELED: 'CANCELED',
};

export const SORT_TYPE_ORDER = {
  ORDER_DATE_ASC: 'orderDate,asc',
  ORDER_DATE_DESC: 'orderDate,desc',
  BILLINg_DATE_ASC: 'scheduleBillingDate,asc',
  BILLINg_DATE_DESC: 'scheduleBillingDate,desc',
  LAST_MODIFIED_DESC: 'lastModifiedDate,desc',
  LAST_MODIFIED_ASC: 'lastModifiedDate,asc',
};

export const SORT_TYPE_COLUMN_ORDER = {
  ORDER_DATE: 'orderDate',
  BILLINg_DATE: 'scheduleBillingDate',
};

export const LINE_TYPE = {
  NORMAL: 'NORMAL',
  TEXT: 'TEXT',
};

export const ERROR = {
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  InternalServerError: 500,
  BadGateway: 502,
};

export const ERROR_CODE = {
  MSG_013: 'MSG_013',
  MSG_016: 'MSG_016',
  MSG_036: 'MSG_036',
  MSG_031: 'MSG_031',
};
