import { useLayoutContext } from '@crema/context/AppContextProvider/LayoutContextProvider';
import { useAppStateContext } from '@crema/context/AppContextProvider/StateContextProvider';
import { RouterConfigData } from '@crema/types/models/Apps';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';

import { ShowMenuIcon } from '../../../../assets/icon';
import AppScrollbar from '../../AppScrollbar';
import MainSidebar from '../components/MainSidebar';
import VerticalNav from '../components/VerticalNav';

type AppSidebarProps = {
  position?: 'left' | 'top' | 'right' | 'bottom';
  variant?: string;
  routesConfig: RouterConfigData[];
  isNavCollapsed: boolean;
  toggleNavCollapsed: () => void;
};

const AppSidebar: FC<AppSidebarProps> = ({ routesConfig }) => {
  const { footer, footerType } = useLayoutContext();
  const { appState, setAppState } = useAppStateContext();

  const handleOnClick = () => {
    setAppState({
      ...appState,
      hiddenDrawer: !appState.hiddenDrawer,
    });
  };

  return (
    <>
      <MainSidebar>
        <AppScrollbar
          className={clsx({
            'has-footer-fixed': footer && footerType === 'fixed',
          })}
          sx={{
            height: 'calc(100vh - 48px) !important',
            borderTop: (theme: { palette: { divider: string } }) =>
              `solid 1px ${theme.palette.divider}`,
            mt: appState.msg ? '78px' : '48px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleOnClick}
              sx={{
                minWidth: '0',
                padding: '0',
                margin: '8px 0px 8px 10px',
                transition: 'transform 0.3s ease',
                transform: appState.hiddenDrawer
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
                boxShadow: 'none',
              }}
            >
              <ShowMenuIcon />
            </Button>
          </Box>
          <VerticalNav routesConfig={routesConfig} />
        </AppScrollbar>
      </MainSidebar>
    </>
  );
};

export default AppSidebar;
