import type { SVGProps } from 'react';

export const ApplicationIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 12.0013C14.0667 12.0013 14.6667 11.4013 14.6667 10.668V4.0013C14.6667 3.26797 14.0667 2.66797 13.3333 2.66797H2.66667C1.93333 2.66797 1.33333 3.26797 1.33333 4.0013V10.668C1.33333 11.4013 1.92667 12.0013 2.66667 12.0013H0.666667C0.3 12.0013 0 12.3013 0 12.668C0 13.0346 0.3 13.3346 0.666667 13.3346H15.3333C15.7 13.3346 16 13.0346 16 12.668C16 12.3013 15.7 12.0013 15.3333 12.0013H13.3333ZM8.66667 9.64797V8.18797C6.81333 8.18797 5.59333 8.75463 4.66667 10.0013C5.04 8.2213 6.07333 6.44797 8.66667 6.08797V4.66797L11.0733 6.90797C11.2133 7.0413 11.2133 7.2613 11.0733 7.39464L8.66667 9.64797Z"
        fill="#454545"
      />
    </svg>
  );
};
