import type { SVGProps } from 'react';

export const HomeIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66673 12.6671V9.3338H9.3334V12.6671C9.3334 13.0338 9.6334 13.3338 10.0001 13.3338H12.0001C12.3667 13.3338 12.6667 13.0338 12.6667 12.6671V8.00047H13.8001C14.1067 8.00047 14.2534 7.62047 14.0201 7.42047L8.44673 2.40047C8.1934 2.1738 7.80673 2.1738 7.5534 2.40047L1.98007 7.42047C1.7534 7.62047 1.8934 8.00047 2.20007 8.00047H3.3334V12.6671C3.3334 13.0338 3.6334 13.3338 4.00007 13.3338H6.00007C6.36673 13.3338 6.66673 13.0338 6.66673 12.6671Z"
        fill="#454545"
      />
    </svg>
  );
};
