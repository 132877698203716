export const MESSAGE = Object.freeze({
  MSG001: 'common.MSG001',
  MSG002: 'common.MSG002',
  MSG003: 'common.MSG003',
  MSG004: 'common.MSG004',
  MSG005: 'common.MSG005',
  MSG006: 'common.MSG006',
  MSG007: 'common.MSG007',
  MSG008: (value: string) => `common.MSG008.${value}`,
  MSG009: 'common.MSG009',
  MSG010: 'common.MSG010',
  MSG011: 'common.MSG011',
  MSG012: 'common.MSG012',
  MSG013: 'common.MSG013',
  MSG014_1: 'common.MSG014.1',
  MSG014_2: 'common.MSG014.2',
  MSG015: 'common.MSG015',
  MSG016: 'common.MSG016',
  MSG017: 'common.MSG017',
  MSG018: 'common.MSG018',
  MSG019: 'common.MSG019',
  MSG020: 'common.MSG020',
  MSG021: 'common.MSG021',
  MSG022: 'common.MSG022',
  MSG023: 'common.MSG023',
  MSG024: 'common.MSG024',
  MSG025: 'common.MSG025',
  MSG026: 'common.MSG026',
  MSG027: 'common.MSG027',
  MSG028: 'common.MSG028',
  MSG029: 'common.MSG029',
  MSG030: 'common.MSG030',
  MSG031: 'common.MSG031',
  MSG033: (value: string, from: string = '') =>
    value === 'code' ? `common.MSG033.code.${from}` : `common.MSG033.${value}`,
  MSG034: 'common.MSG034',
  MSG035: 'common.MSG035',
  MSG036: 'common.MSG036',
} as const);
