import type { SVGProps } from 'react';

export const ResetIcon = ({
  width = 32,
  height = 32,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6503 10.35C20.0203 8.71999 17.7103 7.77999 15.1703 8.03999C11.5003 8.40999 8.48027 11.39 8.07027 15.06C7.52027 19.91 11.2703 24 16.0003 24C19.1903 24 21.9303 22.13 23.2103 19.44C23.5303 18.77 23.0503 18 22.3103 18C21.9403 18 21.5903 18.2 21.4303 18.53C20.3003 20.96 17.5903 22.5 14.6303 21.84C12.4103 21.35 10.6203 19.54 10.1503 17.32C9.31027 13.44 12.2603 9.99999 16.0003 9.99999C17.6603 9.99999 19.1403 10.69 20.2203 11.78L18.7103 13.29C18.0803 13.92 18.5203 15 19.4103 15H23.0003C23.5503 15 24.0003 14.55 24.0003 14V10.41C24.0003 9.51999 22.9203 9.06999 22.2903 9.69999L21.6503 10.35Z"
        fill="#454545"
      />
    </svg>
  );
};
