import { AppColors } from '@crema/constants/AppColors';
import { Fonts } from '@crema/constants/AppEnums';
import { roundToOneDecimal } from '@crema/helpers/StringHelper';
import { SxProps, Theme, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  amount: number;
  sx?: SxProps<Theme>;
  isShowUnit?: boolean;
  variant: VariantType;
};

type VariantType = 'small' | 'medium' | 'large';

const variants: Record<VariantType, React.CSSProperties> = {
  small: {
    color: AppColors.light.black,
    fontSize: '11px',
    lineHeight: '14px',
    letterSpacing: '0.04em',
  },
  medium: {
    color: AppColors.light.black,
    fontSize: '14px',
    fontWeight: Fonts.REGULAR,
    lineHeight: '23px',
    letterSpacing: '0.04em',
  },
  large: {
    color: AppColors.light.black,
    fontSize: '14px',
    fontWeight: Fonts.BOLD,
    lineHeight: '23px',
    letterSpacing: '0.04em',
  },
};

const AppMoney: FC<Props> = ({
  amount,
  sx,
  isShowUnit = true,
  variant = 'small',
}) => {
  return (
    <Typography
      sx={{
        ...variants[variant],
        ...sx,
      }}
    >
      {roundToOneDecimal(amount)}
      {isShowUnit ? '円' : ''}
    </Typography>
  );
};

export default AppMoney;
