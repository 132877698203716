import { useMediaQuery, useTheme } from '@mui/material';
import { Breakpoint } from '@mui/system';
import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

export const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};
export const getBreakPointsValue = (valueSet: any, breakpoint: Breakpoint) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const getFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024,
    dm = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const multiPropsFilter = (
  products: any[],
  filters: any,
  stringKey = 'title',
) => {
  const filterKeys = Object.keys(filters);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (filters[key].length === 0) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle: any) =>
          filters[key].includes(keyEle),
        );
      }
      if (filters[key].end) {
        if (key === 'mrp') {
          return (
            product[key] >= filters[key].start &&
            product[key] < filters[key].end
          );
        } else {
          const start = new Date(filters[key].start).getTime();
          const end = new Date(filters[key].end).getTime();
          const productDate = new Date(product[key]).getTime();

          return productDate >= start && productDate <= end;
        }
      }
      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase());
      }
      return filters[key].includes(product[key]);
    });
  });
};

// 'intl' service singleton reference
let intl: IntlShape;
export const IntlGlobalProvider = ({
  children,
}: {
  children: ReactNode;
}): any => {
  intl = useIntl();
  // Keep the 'intl' service reference
  return children;
};

export const appIntl = () => {
  return intl;
};

export const generateRandomUniqueNumber = (): number => {
  const numbers: number[] = [];
  const number = Math.floor(Math.random() * 100000000);

  if (numbers.includes(number)) {
    return generateRandomUniqueNumber();
  } else {
    numbers.push(number);
    return number;
  }
};

export const createListName = (
  data: any[],
  mapItemFn: (item: any) => {
    label: string;
    value: string;
    [key: string]: any;
  },
) => {
  const listName = data?.map(mapItemFn) || [];
  return listName;
};

export const findNameById = (items: any, id: number): string => {
  return Array.isArray(items)
    ? items.find((item) => item.id === id)?.name || ''
    : '';
};

export const formattedMultiLineText = (text: string) =>
  text?.split('\n')?.map((line, index) => (
    <span key={index}>
      {line}
      {index < text?.split('\n').length - 1 && <br />}
    </span>
  ));

export const formatPrice = (price?: string) => {
  if (price === null || price === '') return '';
  if (Number(price) === 0) return '0';
  return price && `${price}`?.length > 3 ? `${price}`.replace(/,/g, '') : price;
};

export const getQueryParam = (location: any, param: any) => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(param);
};

export const disableInputKana = (e: React.ChangeEvent<HTMLInputElement>) => {
  const kanaRegex = /^[\u30A0-\u30FF\uFF65-\uFF9F]*$/;
  const inputValue = e.target.value;
  const filteredValue = Array.from(inputValue)
    .filter((char) => kanaRegex.test(char))
    .join('');
  e.target.value = filteredValue;
};

export const findMenuById = (menu: any, id: any) => {
  return menu.find((item: any) => item.value == id) || null;
};

export const formatNumericInput = (value: string) => {
  return value.replace(/[^0-9]/g, '').slice(0, 13);
};
