import { InfoViewActions } from '@crema/context/AppContextProvider/InfoViewContextProvider';
import {
  buildQueryString,
  isRequestSuccessful,
} from '@crema/helpers/ApiHelper';
import { decodeBase64 } from '@crema/helpers/StringHelper';
import jwtAxios from '@crema/services/axios';
import {
  APIErrorProps,
  APIErrorResProps,
} from '@crema/types/models/APIDataProps';
import { AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';

export const useGetDataApi = <T,>(
  url: string,
  initialData?: T,
  params?: any,
  initialCall?: boolean,
  callbackFun?: (data: T) => void,
  initCount?: number,
) => {
  if (initialCall === undefined) {
    initialCall = true;
  }

  const [initialUrl, setInitialUrl] = useState<string>(url);
  const [allowApiCall, setAllowApiCall] = useState<boolean>(initialCall);
  const [loading, setLoading] = useState<boolean>(initialCall);
  const [apiData, setData] = useState<T>(initialData ? initialData : ([] as T));
  const [totalCount, setTotalCount] = useState<number>(initCount ?? 0);
  const [defaultSelect, setDefaultSelect] = useState<string>('');
  const [defaultSelectId, setDefaultSelectId] = useState<string>('');
  const [queryParams, updateQueryParams] = useState<object>(params);
  const resStateRef = useRef<boolean>(false);
  const didCancelRef = useRef<boolean>(false);

  const updateInitialUrl = (value: string) => {
    setAllowApiCall(true);
    setInitialUrl(value);
  };

  const reCallAPI = () => {
    setQueryParams(queryParams);
  };

  const setQueryParams = (queryParams: object) => {
    setLoading(true);
    updateQueryParams({ ...queryParams });
    setAllowApiCall(true);
  };

  useEffect(() => {
    didCancelRef.current = false;
    const fetchData = () => {
      resStateRef.current = true;
      let params = {};
      if (!isEmpty(queryParams)) {
        params = {
          ...trimObjectValues(queryParams),
        };
      }
      const sanitizedParams = params;
      const queryString = buildQueryString(sanitizedParams);
      const urlWithParams = queryString
        ? `${initialUrl}?${queryString}`
        : initialUrl;

      jwtAxios
        .get(urlWithParams)
        .then((data: AxiosResponse) => {
          resStateRef.current = false;
          if (!didCancelRef.current) {
            if (isRequestSuccessful(data.status)) {
              setLoading(false);
              const total = data.headers['x-total-count'] ?? 0;
              const select = data.headers['x-default-value'];
              const selectId = data.headers['x-default-id'];
              const utf8String = decodeBase64(select);

              setDefaultSelect((utf8String as string) || '');
              setDefaultSelectId(selectId || '');
              setData(data.data);
              setTotalCount(total);
              if (callbackFun) callbackFun(data.data);
            } else {
              setLoading(false);
              console.error('Error', data.data);
              setData(initialData ? initialData : ({} as T));
              setTotalCount(initCount ?? 0);
              setDefaultSelect('');
              setDefaultSelectId('');
              if (callbackFun) callbackFun(data.data);
            }
          }
        })
        .catch((error: APIErrorProps) => {
          if (error.response?.data.message) {
            if (callbackFun) callbackFun(error.response.data as T);
          } else {
            if (callbackFun) callbackFun(error as T);
          }
          setLoading(false);
        });
    };
    if (allowApiCall && !resStateRef.current) fetchData();
    return () => {
      didCancelRef.current = true;
    };
  }, [initialUrl, queryParams, allowApiCall]);
  return [
    {
      loading,
      apiData,
      totalCount,
      initialUrl,
      defaultSelect,
      defaultSelectId,
    },
    {
      setData,
      setLoading,
      updateInitialUrl,
      setQueryParams,
      reCallAPI,
    },
  ] as const;
};

export const trimObjectValues = (obj: any) => {
  if (isEmpty(obj)) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    }
  });
  return obj;
};

const handleApiResponse = <T,>(
  _: string, // url
  fetchSuccess: () => void,
  data: AxiosResponse<T>,
  resolve: (data: T) => void,
  reject: (data: APIErrorResProps) => void,
) => {
  fetchSuccess();
  if (isRequestSuccessful(data.status)) {
    return resolve(data.data);
  } else {
    return reject(data.data as APIErrorResProps);
  }
};

const handleAPIError = (
  _: string, // url
  fetchSuccess: () => void,
  error: APIErrorProps,
  reject: (data: APIErrorResProps) => void,
) => {
  fetchSuccess();
  if (error?.response?.data.message) {
    return reject(error?.response?.data);
  } else {
    return reject(error as APIErrorResProps);
  }
};

export const postDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  payload: object,
  isHideLoader?: boolean,
  headers = {},
): Promise<T> => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .post(url, payload, headers ? { headers } : {})
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const putDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  payload: object,
  isHideLoader = false,
): Promise<T> => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .put(url, payload)
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};
export const patchDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  payload: object,
  isHideLoader = false,
): Promise<T> => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .patch(url, payload)
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const getDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  params = {},
  isHideLoader = false,
  headers = {},
): Promise<T> => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .get(url, { params: params, headers })
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const deleteDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  params = {},
  isHideLoader = false,
): Promise<T> => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .delete(url, { params })
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const uploadDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  payload = {},
  isHideLoader = false,
  onUploadProgress = () => {},
  allowDownload = false,
): Promise<T> => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .post(url, payload, {
        onUploadProgress,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        responseType: allowDownload ? 'arraybuffer' : 'stream',
      })
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const uploadPutDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  payload = {},
  isHideLoader = false,
) => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    jwtAxios
      .put(url, payload, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};
