import type { SVGProps } from 'react';

export const SaleIcon = ({
  width = 16,
  height = 16,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2335 4.56667L12.1935 5.52667L8.94015 8.78L6.74682 6.58667C6.48682 6.32667 6.06682 6.32667 5.80682 6.58667L1.80682 10.5933C1.54682 10.8533 1.54682 11.2733 1.80682 11.5333C2.06682 11.7933 2.48682 11.7933 2.74682 11.5333L6.27348 8L8.46682 10.1933C8.72682 10.4533 9.14682 10.4533 9.40682 10.1933L13.1335 6.47333L14.0935 7.43333C14.3001 7.64 14.6602 7.49333 14.6602 7.2V4.33333C14.6668 4.14667 14.5202 4 14.3335 4H11.4735C11.1735 4 11.0268 4.36 11.2335 4.56667Z"
        fill="#454545"
      />
    </svg>
  );
};
