export const TAB_TYPE = {
  ALL: '',
  USE_AVAILABLE: 'USE_AVAILABLE',
  USE_STOPPED: 'USE_STOPPED',
};

export const SORT_TYPE = {
  DEFAULT: 'createdDate,desc',
  LAST_UPDATED: 'lastModifiedDate,desc',
  LAST_UPDATED_ASC: 'lastModifiedDate,asc',
};
