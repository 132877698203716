import { useAppStateContext } from '@crema/context/AppContextProvider/StateContextProvider';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';

import AppErrorBoundary from '../AppErrorBoundary';
import AppFooter from '../AppLayout/components/AppFooter';
import AppSuspense from '../AppSuspense';
import AppContentViewWrapper from './AppContentViewWrapper';

type AppContentViewProps = {
  sxStyle?: SxProps;
  routes: React.ReactElement | null;
};

const AppContentView: React.FC<AppContentViewProps> = ({ routes, sxStyle }) => {
  const { appState } = useAppStateContext();

  return (
    <AppContentViewWrapper>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          p: '20px',
          marginTop: appState && appState.msg ? '30px' : 0,
          ...sxStyle,
        }}
        className="app-content"
      >
        <AppSuspense>
          <AppErrorBoundary>{routes}</AppErrorBoundary>
        </AppSuspense>
      </Box>
      <AppFooter />
    </AppContentViewWrapper>
  );
};

export default AppContentView;
