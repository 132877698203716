import { ERROR } from '@crema/constants/AppConst';
import { Routes } from '@crema/constants/Routes';
import { useAppStateContext } from '@crema/context/AppContextProvider/StateContextProvider';
import { MESSAGE } from '@crema/helpers/MessageList';
import { useAuthMethod } from '@crema/hooks/AuthHooks';
import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { setAuthToken } from '../auth/jwt-auth';
import apiConfig from './ApiConfig';

const ERROR_CODE_401 = {
  SESSION_EXPIRED: 'MSG_031',
  JWT_TOKEN_CHANGED: 'MSG_036',
};

const ApiInterceptor = () => {
  const { logout } = useAuthMethod();
  const navigate = useNavigate();
  const { appState, setAppState } = useAppStateContext();

  const getQueryParam = (param: any) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const redirect = getQueryParam('redirect');

  const setupApiInterceptor = (
    logout: () => void,
    navigate: any,
    setAppState: any,
    appState: any,
  ) => {
    apiConfig.interceptors.response.use(
      (res: AxiosResponse<any, any>) => res,
      (err: any) => {
        if (!navigator.onLine) {
          setAppState({
            msg: MESSAGE.MSG016,
            type: 'error',
          });
        }

        if (!err.response?.status) {
          setAppState({
            ...appState,
            msg: MESSAGE.MSG016,
            type: 'error',
          });
          return Promise.reject(null);
        }

        switch (err.response?.status) {
          case ERROR.Unauthorized:
            localStorage.clear();
            setAuthToken();
            logout();

            if (err.response?.data?.code === ERROR_CODE_401.SESSION_EXPIRED) {
              setAppState({
                ...appState,
                msg: MESSAGE.MSG031,
                type: 'error',
              });
            } else if (
              err.response?.data?.code === ERROR_CODE_401.JWT_TOKEN_CHANGED
            ) {
              setAppState({
                ...appState,
                msg: MESSAGE.MSG036,
                type: 'error',
              });
            } else {
              setAppState({
                ...appState,
                msg: MESSAGE.MSG013,
                type: 'error',
              });
            }
            navigate(Routes.signIn, { replace: true });
            break;
          case ERROR.Forbidden:
            navigate(Routes.error403);
            break;
          case ERROR.NotFound:
            navigate(Routes.error404);
            break;
          case ERROR.BadGateway:
          case ERROR.InternalServerError:
            navigate(Routes.maintain);
            break;
        }

        return Promise.reject(err);
      },
    );
  };

  useEffect(() => {
    setupApiInterceptor(logout, navigate, setAppState, appState);
  }, [logout, navigate, setAppState, appState, redirect]);

  return null;
};

export default ApiInterceptor;
