import { useGetDataApi } from '@crema/hooks/APIHooks';
import React, { useEffect } from 'react';
import { ReactNode, createContext, useState } from 'react';

type AppStateType = {
  msg?: string;
  hiddenDrawer?: boolean;
  type?: string;
};

type UserStateType = {
  departments?: Array<any>;
  departmentSelect?: string;
  currentOffices?: any;
  userCurrent?: any;
};

type AppStateContextType = {
  appState: AppStateType;
  setAppState: React.Dispatch<React.SetStateAction<AppStateType>>;
  userState: UserStateType;
  setLoggedIn: any;
  handleUpdateOffices: () => void;
  handleUpdateUser: () => void;
  handleUpdateDepartments: () => void;
};

const AppStateContext = createContext<AppStateContextType>({
  appState: {
    msg: '',
    hiddenDrawer: false,
    type: '',
  },
  setAppState: () => {},
  setLoggedIn: () => {},
  userState: {
    departments: [],
    departmentSelect: '',
    currentOffices: {},
    userCurrent: {},
  },
  handleUpdateOffices: () => {},
  handleUpdateUser: () => {},
  handleUpdateDepartments: () => {},
});

interface AppStateProviderProps {
  children: ReactNode;
}

const AppStateProvider = ({ children }: AppStateProviderProps) => {
  const [appState, setAppState] = useState<AppStateType>({
    msg: '',
    hiddenDrawer: false,
    type: '',
  });

  const [userState, setUserState] = useState<UserStateType>({
    departments: [],
    departmentSelect: '',
    currentOffices: {},
    userCurrent: {},
  });
  const token = localStorage.getItem('token');
  const [
    { apiData: departments, defaultSelect },
    {
      setQueryParams,
      reCallAPI: handleUpdateDepartments,
      updateInitialUrl: initDepartmentUrl,
    },
  ] = useGetDataApi<any>(
    token ? '/departments/all?currentOnly=true' : '',
    [],
    {},
    false,
  );

  const [
    { apiData: currentOffices },
    {
      setQueryParams: setQueryParamsOffices,
      reCallAPI: handleUpdateOffices,
      updateInitialUrl: initOfficeUrl,
    },
  ] = useGetDataApi<any>(token ? '/offices/current-users' : '', {}, {}, false);

  const [
    { apiData: userCurrent },
    {
      setQueryParams: setQueryParamsUserCurrent,
      reCallAPI: handleUpdateUser,
      updateInitialUrl: initUserUrl,
    },
  ] = useGetDataApi<any>(token ? '/admin/users/current' : '', {}, {}, false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      setQueryParams({});
      setQueryParamsOffices({});
      setQueryParamsUserCurrent({});
    }
  }, [token]);

  useEffect(() => {
    if (loggedIn) {
      initDepartmentUrl('/departments/all?currentOnly=true');
      initOfficeUrl('/offices/current-users');
      initUserUrl('/admin/users/current');
    }
  }, [loggedIn]);

  useEffect(() => {
    setUserState((prevState) => ({
      ...prevState,
      departments,
      departmentSelect: defaultSelect,
      currentOffices,
      userCurrent,
    }));
  }, [departments, defaultSelect, currentOffices, userCurrent]);

  useEffect(() => {
    setUserState({
      departments,
      departmentSelect: defaultSelect,
      currentOffices,
      userCurrent,
    });
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (appState.msg || appState.type) {
      timer = setTimeout(() => {
        setAppState((prevState) => ({
          ...prevState,
          msg: '',
          type: '',
        }));
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [appState.msg, appState.type]);

  return (
    <AppStateContext.Provider
      value={{
        appState,
        setAppState,
        userState,
        setLoggedIn,
        handleUpdateOffices,
        handleUpdateUser,
        handleUpdateDepartments,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppStateContext = () => React.useContext(AppStateContext);

export { AppStateProvider };
