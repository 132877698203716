import { RoutePermittedRole, Routes } from '@crema/constants';
import React from 'react';
// import QuotationForm from '../../../modules/sm99/Quotation/QuotationForm';
// import OrderForm from '../../../modules/sm99/Orders/OrderForm';
// import DeliveryForm from '../../../modules/sm99/Delivery/DeliveryForm';
// import BillingForm from '../../../modules/sm99/Billing/BillingForm';
// import BillingDetail from '../../../modules/sm99/Billing/BillDetail';
// import QuotationDetail from '../../../modules/sm99/Quotation/QuotationDetail';
// import OrderDetail from '../../../modules/sm99/Orders/OrderDetail';
// import DeliveryDetail from '../../../modules/sm99/Delivery/DeliveryDetail';

const Home = React.lazy(() => import('../../../modules/sm99/Home'));
const NotificationDetail = React.lazy(
  () => import('../../../modules/sm99/Home/NotificationDetail'),
);
const NotificationList = React.lazy(
  () => import('../../../modules/sm99/Home/NoticeTable'),
);
const ToDoList = React.lazy(
  () => import('../../../modules/sm99/Home/ToDoTable'),
);

const Customers = React.lazy(() => import('../../../modules/sm99/Customers'));
const CustomerCreate = React.lazy(
  () => import('../../../modules/sm99/Customers/CustomerForm'),
);
const CustomerEdit = React.lazy(
  () => import('../../../modules/sm99/Customers/CustomerFormEdit'),
);
const CustomerUploadCSV = React.lazy(
  () => import('../../../modules/sm99/Customers/CustomerCSV'),
);
const CustomerDetail = React.lazy(
  () => import('../../../modules/sm99/Customers/CustomerDetail'),
);

const Situations = React.lazy(() => import('../../../modules/sm99/Situations'));
const SituationsForm = React.lazy(
  () => import('../../../modules/sm99/Situations/SituationForm'),
);
const SituationDetail = React.lazy(
  () => import('../../../modules/sm99/Situations/SituationDetail'),
);

const Quotation = React.lazy(() => import('../../../modules/sm99/Quotation'));
const QuotationDetail = React.lazy(
  () => import('../../../modules/sm99/Quotation/QuotationDetail'),
);
const QuotationForm = React.lazy(
  () => import('../../../modules/sm99/Quotation/QuotationForm'),
);

const Orders = React.lazy(() => import('../../../modules/sm99/Orders'));
const OrderDetail = React.lazy(
  () => import('../../../modules/sm99/Orders/OrderDetail'),
);
const OrderForm = React.lazy(
  () => import('../../../modules/sm99/Orders/OrderForm'),
);

const Delivery = React.lazy(() => import('../../../modules/sm99/Delivery'));
const DeliveryDetail = React.lazy(
  () => import('../../../modules/sm99/Delivery/DeliveryDetail'),
);
const DeliveryForm = React.lazy(
  () => import('../../../modules/sm99/Delivery/DeliveryForm'),
);

const Sales = React.lazy(() => import('../../../modules/sm99/Sales'));
const SaleDetail = React.lazy(
  () => import('../../../modules/sm99/Sales/SalesDetail'),
);
const SalesForm = React.lazy(
  () => import('../../../modules/sm99/Sales/SalesForm'),
);

const Billing = React.lazy(() => import('../../../modules/sm99/Billing'));
const BillingForm = React.lazy(
  () => import('../../../modules/sm99/Billing/BillingForm'),
);
const BillingDetail = React.lazy(
  () => import('../../../modules/sm99/Billing/BillDetail'),
);

const Application = React.lazy(
  () => import('../../../modules/sm99/Application'),
);
const ApplicationList = React.lazy(
  () => import('../../../modules/sm99/ApplicationList'),
);

const ApprovalList = React.lazy(
  () => import('../../../modules/sm99/ApprovalList'),
);

const Bookkeeping = React.lazy(
  () => import('../../../modules/sm99/Bookkeeping'),
);

const Payment = React.lazy(() => import('../../../modules/sm99/Payment'));
const Withdrawal = React.lazy(() => import('../../../modules/sm99/Withdrawal'));
const Journal = React.lazy(() => import('../../../modules/sm99/Journal'));
const AccountingAccount = React.lazy(
  () => import('../../../modules/sm99/AccountingAccount'),
);

const Settings = React.lazy(() => import('../../../modules/sm99/Settings'));
const SettingsProduct = React.lazy(
  () => import('../../../modules/sm99/Settings/SettingProduct'),
);
const SettingsProductCreate = React.lazy(
  () => import('../../../modules/sm99/Settings/SettingProduct/ProductForm'),
);
const SettingsProductDetail = React.lazy(
  () => import('../../../modules/sm99/Settings/SettingProduct/ProductDetail'),
);

const SettingsDataOffice = React.lazy(
  () => import('../../../modules/sm99/Settings/SettingData/SettingOffice'),
);
const SettingsDataDepartment = React.lazy(
  () => import('../../../modules/sm99/Settings/SettingData/SettingDepartment'),
);
const SettingsDataDepartmentCreate = React.lazy(
  () =>
    import(
      '../../../modules/sm99/Settings/SettingData/SettingDepartment/DepartmentForm'
    ),
);
const SettingsDataDepartmentDetail = React.lazy(
  () =>
    import(
      '../../../modules/sm99/Settings/SettingData/SettingDepartment/DepartmentDetail'
    ),
);
const SettingsDataDepartmentEdit = React.lazy(
  () =>
    import(
      '../../../modules/sm99/Settings/SettingData/SettingDepartment/DepartmentFormEdit'
    ),
);
const SettingsDataBankAccount = React.lazy(
  () => import('../../../modules/sm99/Settings/SettingData/SettingBankAccount'),
);
const SettingsDataBankAccountCreate = React.lazy(
  () =>
    import(
      '../../../modules/sm99/Settings/SettingData/SettingBankAccount/BankAccountForm'
    ),
);
const SettingsDataBankAccountDetail = React.lazy(
  () =>
    import(
      '../../../modules/sm99/Settings/SettingData/SettingBankAccount/BankAccountDetail'
    ),
);
const SettingsDataNotice = React.lazy(
  () => import('../../../modules/sm99/Settings/SettingData/SettingNotice'),
);
const SettingsDataNoticeCreate = React.lazy(
  () =>
    import(
      '../../../modules/sm99/Settings/SettingData/SettingNotice/NoticeForm'
    ),
);
const SettingsDataNoticeDetail = React.lazy(
  () =>
    import(
      '../../../modules/sm99/Settings/SettingData/SettingNotice/NoticeDetail'
    ),
);
const SettingsMasterAccount = React.lazy(
  () => import('../../../modules/sm99/Settings/SettingMaster/SettingAccount'),
);
const SettingsMasterAccountCreate = React.lazy(
  () =>
    import(
      '../../../modules/sm99/Settings/SettingMaster/SettingAccount/AccountForm'
    ),
);
const SettingsMasterAccountDetail = React.lazy(
  () =>
    import(
      '../../../modules/sm99/Settings/SettingMaster/SettingAccount/AccountDetail'
    ),
);

const YourShare = React.lazy(() => import('../../../modules/sm99/YourShare'));
const MyShare = React.lazy(() => import('../../../modules/sm99/MyShare'));
const View = React.lazy(() => import('../../../modules/sm99/View'));

export const appRoutesConfig = [
  // SM99 defined
  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.home,
    element: <Home />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.customers,
    element: <Customers />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.notifications,
    element: <NotificationList />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.todoList,
    element: <ToDoList />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.notificationsDetail,
    element: <NotificationDetail />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.customerCreate,
    element: <CustomerCreate />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.customerEdit,
    element: <CustomerEdit />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.customerDetail,
    element: <CustomerDetail />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.customerDuplicate,
    element: <CustomerEdit />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.customerUploadCSV,
    element: <CustomerUploadCSV />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.situation,
    element: <Situations />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.situationCreate,
    element: <SituationsForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.situationDuplicate,
    element: <SituationsForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.situationEdit,
    element: <SituationsForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.situationDetail,
    element: <SituationDetail />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.quotation,
    element: <Quotation />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.quotationCreate,
    element: <QuotationForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.quotationEdit,
    element: <QuotationForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.quotationDuplicate,
    element: <QuotationForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.quotationsDetail,
    element: <QuotationDetail />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.order,
    element: <Orders />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.orderCreate,
    element: <OrderForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.orderEdit,
    element: <OrderForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.orderDuplicate,
    element: <OrderForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.orderDetail,
    element: <OrderDetail />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.delivery,
    element: <Delivery />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.deliveryCreate,
    element: <DeliveryForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.deliveryEdit,
    element: <DeliveryForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.deliveryDuplicate,
    element: <DeliveryForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.deliveryDetail,
    element: <DeliveryDetail />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.sale,
    element: <Sales />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.saleCreate,
    element: <SalesForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.saleDuplicate,
    element: <SalesForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.saleEdit,
    element: <SalesForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.saleDetail,
    element: <SaleDetail />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.billing,
    element: <Billing />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.billingCreate,
    element: <BillingForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.billingEdit,
    element: <BillingForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.billingDuplicate,
    element: <BillingForm />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.billingDetail,
    element: <BillingDetail />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.application,
    element: <Application />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.applicationList,
    element: <ApplicationList />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.approvalList,
    element: <ApprovalList />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.payment,
    element: <Payment />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.withdrawal,
    element: <Withdrawal />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.journal,
    element: <Journal />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.accountingAccount,
    element: <AccountingAccount />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.bookkeeping,
    element: <Bookkeeping />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.settings,
    element: <Settings />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.settingsProduct,
    element: <SettingsProduct />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.settingsProductCreate,
    element: <SettingsProductCreate />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.settingsProductEdit,
    element: <SettingsProductCreate />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.settingsProductDuplicate,
    element: <SettingsProductCreate />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.settingsProductDetail,
    element: <SettingsProductDetail />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataOffice,
    element: <SettingsDataOffice />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataDepartment,
    element: <SettingsDataDepartment />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataDepartmentCreate,
    element: <SettingsDataDepartmentCreate />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataDepartmentDetail,
    element: <SettingsDataDepartmentDetail />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataDepartmentEdit,
    element: <SettingsDataDepartmentEdit />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataDepartmentDuplicate,
    element: <SettingsDataDepartmentEdit />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataBankAccount,
    element: <SettingsDataBankAccount />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataBankAccountCreate,
    element: <SettingsDataBankAccountCreate />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataBankAccountEdit,
    element: <SettingsDataBankAccountCreate />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataBankAccountDuplicate,
    element: <SettingsDataBankAccountCreate />,
  },

  {
    permittedRole: RoutePermittedRole.SettingVarius,
    path: Routes.settingsDataBankAccountDetail,
    element: <SettingsDataBankAccountDetail />,
  },

  {
    permittedRole: RoutePermittedRole.SettingNotification,
    path: Routes.settingsDataNotice,
    element: <SettingsDataNotice />,
  },

  {
    permittedRole: RoutePermittedRole.SettingNotification,
    path: Routes.settingsDataNoticeCreate,
    element: <SettingsDataNoticeCreate />,
  },

  {
    permittedRole: RoutePermittedRole.SettingNotification,
    path: Routes.settingsDataNoticeEdit,
    element: <SettingsDataNoticeCreate />,
  },

  {
    permittedRole: RoutePermittedRole.SettingNotification,
    path: Routes.settingsDataNoticeDetail,
    element: <SettingsDataNoticeDetail />,
  },

  {
    permittedRole: RoutePermittedRole.SettingAccount,
    path: Routes.settingsMasterAccount,
    element: <SettingsMasterAccount />,
  },

  {
    permittedRole: RoutePermittedRole.SettingAccount,
    path: Routes.settingsMasterAccountCreate,
    element: <SettingsMasterAccountCreate />,
  },

  {
    permittedRole: RoutePermittedRole.SettingAccount,
    path: Routes.settingsMasterAccountEdit,
    element: <SettingsMasterAccountCreate />,
  },

  {
    permittedRole: RoutePermittedRole.SettingAccount,
    path: Routes.settingsMasterAccountDuplicate,
    element: <SettingsMasterAccountCreate />,
  },

  {
    permittedRole: RoutePermittedRole.SettingAccount,
    path: Routes.settingsMasterAccountDetail,
    element: <SettingsMasterAccountDetail />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.yourShare,
    element: <YourShare />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.myShare,
    element: <MyShare />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: Routes.view,
    element: <View />,
  },
];
