export const sumTotal = (orderDetails: any[]) => {
  const excludingTaxSum = orderDetails?.reduce((acc: any, current: any) => {
    return (
      acc +
        (current?.unitPrice
          ? typeof current?.unitPrice !== 'number'
            ? Number(current?.unitPrice.replace(/,/g, ''))
            : current?.unitPrice
          : 0) *
          (current?.quantity
            ? typeof current?.quantity !== 'number'
              ? Number(current?.quantity.replace(/,/g, ''))
              : current?.quantity
            : 0) || 0
    );
  }, 0);

  const consumptionTaxSum = orderDetails?.reduce((acc: any, current: any) => {
    return (
      acc +
        ((current?.unitPrice
          ? typeof current?.unitPrice !== 'number'
            ? Number(current?.unitPrice.replace(/,/g, ''))
            : current?.unitPrice
          : 0) *
          (current?.quantity
            ? typeof current?.quantity !== 'number'
              ? Number(current?.quantity.replace(/,/g, ''))
              : current?.quantity
            : 0) *
          (current?.taxCategory?.taxRate
            ? parseFloat(current?.taxCategory?.taxRate)
            : 0)) /
          100 || 0
    );
  }, 0);

  const total = excludingTaxSum + consumptionTaxSum || 0;

  return {
    excludingTaxSum: excludingTaxSum || 0,
    consumptionTaxSum: consumptionTaxSum || 0,
    total: total || 0,
  };
};

export const getListDepartMent = () => {
  const departmentLocal = localStorage.getItem('departments') || null;
  const listDepartment = departmentLocal ? JSON.parse(departmentLocal) : [];
  return {
    listDepartment,
  };
};
