import AppLogo from '@crema/components/AppLayout/components/AppLogo';
import { Box } from '@mui/material';
import React from 'react';

const HeaderGuest: React.FC = () => {
  return (
    <Box>
      <Box sx={{ height: '48px', borderBottom: '2px solid #00000014' }}>
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '48px',
          }}
        >
          <AppLogo />
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderGuest;
