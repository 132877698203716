/* eslint-disable @typescript-eslint/ban-ts-comment */
import AppContentView from '@crema/components/AppContentView';
import { Layouts } from '@crema/components/AppLayout';
import AppBanner from '@crema/components/AppLayout/DefaultLayout/AppHeader/AppBanner';
import { initialUrl } from '@crema/constants/AppConst';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
import { Routes } from '@crema/constants/Routes';
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '@crema/context/AppContextProvider/LayoutContextProvider';
import { useSidebarActionsContext } from '@crema/context/AppContextProvider/SidebarContextProvider';
import { useAppStateContext } from '@crema/context/AppContextProvider/StateContextProvider';
import generateRoutes from '@crema/helpers/RouteGenerator';
import { ProcessRoles } from '@crema/helpers/RouteHelper';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import ApiInterceptor from '@crema/services/axios/ApiInterceptor';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
// @ts-ignore
import { useUrlSearchParams } from 'use-url-search-params';

import {
  anonymousStructure,
  authorizedStructure,
  publicStructure,
} from '../AppRoutes';
import routesConfig from '../AppRoutes/routeconfig';
import CSVImport from './CSVImport';
import HeaderGuest from './HeaderGuest';

const AppLayout = () => {
  const { navStyle } = useLayoutContext();

  const { isAuthenticated } = useAuthUser();
  const { appState } = useAppStateContext();

  const { updateNavStyle } = useLayoutActionsContext();
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext();
  const AppLayout = Layouts[navStyle];
  const [params] = useUrlSearchParams();

  const initURL = params?.redirect ? params?.redirect : initialUrl;
  const loginUrl = `${Routes.signIn}?redirect=${window.location.pathname}`;

  let authorities = [];
  const storeRole = localStorage.getItem('authorities');
  if (storeRole) {
    authorities = JSON.parse(storeRole) || [];
  }

  const roles = ProcessRoles(authorities);

  const generatedRoutes = generateRoutes({
    isAuthenticated: isAuthenticated,
    userRole: roles || RoutePermittedRole.User,
    publicStructure: publicStructure(initURL as string),
    authorizedStructure: authorizedStructure(loginUrl),
    anonymousStructure: anonymousStructure(initURL as string),
  });
  const routes = useRoutes(generatedRoutes);

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout as string);
    if (params.menuStyle) updateMenuStyle(params.menuStyle as string);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, [params, setSidebarBgImage, updateNavStyle, updateMenuStyle]);

  return (
    <>
      {isAuthenticated ? (
        <AppLayout routes={routes} routesConfig={routesConfig} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginTop: appState && appState.msg ? '30px' : 0,
          }}
          className="app-content"
        >
          <AppBanner msg={appState.msg} type={appState.type} />
          {!isAuthenticated && <HeaderGuest />}
          <AppContentView routes={routes} />
        </Box>
      )}
      <CSVImport />
      <ApiInterceptor />
    </>
  );
};

export default AppLayout;
