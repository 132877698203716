import { RoutePermittedRole } from '@crema/constants/AppEnums';
import { Routes } from '@crema/constants/Routes';

import {
  AccountingIcon,
  ApplicationIcon,
  ApprovalIcon,
  BookkeepingIcon,
  Caseicon,
  ClaimIcon,
  ContactIcon,
  DeliveryIcon,
  HomeIcon,
  PeopleIcon,
  QuotationIcon,
  SaleIcon,
  SettingIcon,
  ShareIcon,
} from '../../../assets/icon';

const routesConfig = [
  {
    id: 'home',
    title: 'HOME',
    messageId: 'sidebar.home',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <HomeIcon />,
    url: Routes.home,
  },
  {
    id: 'partner',
    title: 'Partner',
    messageId: 'sidebar.partner',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <PeopleIcon />,
    url: Routes.customers,
  },
  {
    id: 'situations',
    title: 'Situation',
    messageId: 'sidebar.case',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <Caseicon />,
    url: Routes.situation,
  },
  {
    id: 'quotation',
    title: 'Quotation',
    messageId: 'sidebar.quotation',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <QuotationIcon />,
    url: Routes.quotation,
  },
  {
    id: 'contact',
    title: 'Contact',
    messageId: 'sidebar.contact',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <ContactIcon />,
    url: Routes.order,
  },
  {
    id: 'delivery',
    title: 'Delivery',
    messageId: 'sidebar.delivery',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <DeliveryIcon />,
    url: Routes.delivery,
  },
  {
    id: 'sales',
    title: 'Sales',
    messageId: 'sidebar.sales',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <SaleIcon />,
    url: Routes.sale,
  },
  {
    id: 'claim',
    title: 'Claim',
    messageId: 'sidebar.claim',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <ClaimIcon />,
    url: Routes.billing,
  },
  {
    id: 'application',
    title: 'Application',
    messageId: 'sidebar.application_jp',
    type: 'collapse',
    permittedRole: [RoutePermittedRole.User],
    icon: <ApplicationIcon />,
    children: [
      {
        id: 'apply',
        title: 'Apply',
        messageId: 'sidebar.application.apply',
        type: 'item',
        permittedRole: [RoutePermittedRole.User],
        url: Routes.application,
      },
      {
        id: 'application_list',
        title: 'Application List',
        messageId: 'sidebar.application.list',
        type: 'item',
        permittedRole: [RoutePermittedRole.User],
        url: Routes.applicationList,
      },
    ],
  },
  {
    id: 'approval',
    title: 'Approval',
    messageId: 'sidebar.approval',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <ApprovalIcon />,
    url: Routes.approvalList,
  },
  {
    id: 'shares',
    title: 'Shares',
    messageId: 'sidebar.share',
    type: 'collapse',
    permittedRole: [RoutePermittedRole.User],
    icon: <ShareIcon />,
    children: [
      {
        id: 'my-share',
        title: 'My Share',
        messageId: 'my.share.header.title',
        type: 'item',
        permittedRole: [RoutePermittedRole.User],
        url: Routes.myShare,
      },
      {
        id: 'your-share',
        title: 'Your Share',
        messageId: 'your.share.header.title',
        type: 'item',
        permittedRole: [RoutePermittedRole.User],
        url: Routes.yourShare,
      },
      {
        id: 'view',
        title: 'View',
        messageId: 'view.header.title',
        type: 'item',
        permittedRole: [RoutePermittedRole.User],
        url: Routes.view,
      },
    ],
  },
  {
    id: 'bookkeeping',
    title: 'Bookkeeping',
    messageId: 'sidebar.accounting',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <BookkeepingIcon />,
    url: Routes.bookkeeping,
  },
  {
    id: 'accounting',
    title: 'Accounting',
    messageId: 'sidebar.bookkeeping',
    type: 'collapse',
    permittedRole: [RoutePermittedRole.User],
    icon: <AccountingIcon />,
    children: [
      {
        id: 'payment',
        title: 'Payment',
        messageId: 'sidebar.bookkeeping.payment',
        type: 'item',
        permittedRole: [RoutePermittedRole.User],
        url: Routes.payment,
      },
      {
        id: 'withdrawal',
        title: 'Withdrawal',
        messageId: 'sidebar.bookkeeping.withdrawal',
        type: 'item',
        permittedRole: [RoutePermittedRole.User],
        url: Routes.withdrawal,
      },
      {
        id: 'journal',
        title: 'Journal',
        messageId: 'sidebar.bookkeeping.journal',
        type: 'item',
        permittedRole: [RoutePermittedRole.User],
        url: Routes.journal,
      },
      {
        id: 'bookkeeping_account',
        title: 'AccountingAccount',
        messageId: 'sidebar.bookkeeping.account',
        type: 'item',
        permittedRole: [RoutePermittedRole.User],
        url: Routes.accountingAccount,
      },
    ],
  },
  {
    id: 'setting',
    title: 'Setting',
    messageId: 'sidebar.setting',
    type: 'item',
    permittedRole: [RoutePermittedRole.User],
    icon: <SettingIcon />,
    url: Routes.settings,
  },
];
export default routesConfig;
