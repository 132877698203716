import { LINE_TYPE } from '@crema/constants/AppConst';
import { TAB_TYPE } from '@crema/constants/components/SettingProducts';
import { Box, Tooltip } from '@mui/material';
import type { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import Decimal from 'decimal.js';

export const getStringFromHtml = (htmlContent: string) => {
  return htmlContent.replace(/(<([^>]+)>)/gi, '');
};

export const generateUniqueID = () => {
  return `v1-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const ellipsisLines = (
  content: string,
  placement: TooltipProps['placement'] = 'top',
) => {
  if (content) {
    return (
      <Tooltip placement={placement} title={content}>
        <Box
          style={{
            display: 'block',
            width: '100%',
            maxWidth: 250,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {content}
          {content.length > 40 && '...'}
        </Box>
      </Tooltip>
    );
  } else {
    return handleNullValue(content);
  }
};

export const handleNullValue = (value: string | null) => {
  if (value === null) {
    return '-';
  } else {
    return value;
  }
};

export const formatCurrency = (
  value: number,
  currencyFormat: {
    language: string;
    currency: string;
  },
  decimalDigits: number,
) => {
  return new Intl.NumberFormat(currencyFormat?.language || 'en-IN', {
    style: 'currency',
    currency: currencyFormat?.currency || 'INR',
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
  }).format(value);
};

export const decodeBase64 = (base64String: string): string => {
  if (!base64String) return '';

  // Remove invalid characters and check for valid Base64 format
  const cleanedString = base64String.replace(/[^A-Za-z0-9+/=]/g, '');

  // Ensure the string length is divisible by 4 (required for Base64 padding)
  const padding = cleanedString.length % 4;
  const paddedString = padding
    ? cleanedString + '='.repeat(4 - padding)
    : cleanedString;

  try {
    // Validate base64 string
    if (!/^[A-Za-z0-9+/]+={0,2}$/.test(paddedString)) {
      console.log('=========> Invalid base64 string format');
      return '';
    }

    // Decode using atob, but catch any errors
    const decodedString = atob(paddedString);

    // Convert the decoded binary string to a UTF-8 string
    const uint8Array = Uint8Array.from(
      decodedString.split('').map((char) => char.charCodeAt(0)),
    );
    const decoder = new TextDecoder('utf-8');

    return decoder.decode(uint8Array);
  } catch (e) {
    console.error('Decoding failed:', e);
    return ''; // Return empty string on failure, or handle as needed
  }
};

export const canParseToNumber = (input: string): boolean => {
  const parsed = Number(input);
  return !isNaN(parsed) && (input.trim() !== '' || input === '0');
};

export const roundToOneDecimal = (value: string | number): string => {
  if (value === null) return '';
  if (Number(value) === 0) return '0';
  const amountString = value.toString().replace(/,/g, '');
  const decimalAmount = new Decimal(amountString);
  const roundedAmount = decimalAmount.toDecimalPlaces(1, Decimal.ROUND_HALF_UP);
  return roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formattedNumber = (value: string | number): string => {
  if (value === null || value === '') return '';
  if (Number(value) === 0) return '0';
  return value.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatRegexPrice = (value: string | number): string => {
  if (value === null) return '';
  if (value === 0) return '0';
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formattedPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber;
  // TODO: wait confirm
  // if (!phoneNumber) return EMPTY_VALUE;

  // if (/^\d{10}$/.test(phoneNumber)) {
  //   return phoneNumber.replace(/^(\d{2})(\d{4})(\d{4})$/, '$1-$2-$3');
  // } else if (/^\d{11}$/.test(phoneNumber)) {
  //   return phoneNumber.replace(/^(\d{2})(\d{4})(\d{5})$/, '$1-$2-$3');
  // } else {
  //   return phoneNumber;
  // }
};

export const handleDataForAPI = (value: Any<string>): Any<string> =>
  !value ? null : { id: value };

export const convertArrayToString = (arr: Array<string>): string => {
  return arr.join(',');
};

export const convertStringToArray = (str: string): Array<string> => {
  return str.split(',');
};

export const getInformationDetails = (data: any) => {
  return data?.length > 0
    ? data.map((item: any) =>
        item.type === LINE_TYPE.TEXT || !item.quantity
          ? {
              type: LINE_TYPE.TEXT,
              textLine: item.textLine,
            }
          : {
              type: LINE_TYPE.NORMAL,
              id: item.id || null,
              product:
                item.product?.status === TAB_TYPE.USE_AVAILABLE
                  ? {
                      ...handleDataForAPI(item.product?.id),
                      status: item.product?.status,
                    }
                  : null,
              summary: item.summary,
              quantity: item.quantity,
              unit: item.unit,
              unitPrice: item.unitPrice,
              taxCategory: item.taxCategory?.id
                ? {
                    id: item.taxCategory?.id,
                    taxRate: Number(item.taxCategory?.taxRate),
                    taxName: item.taxCategory?.taxName,
                  }
                : null,
              textLine: '',
            },
      )
    : [
        {
          type: LINE_TYPE.NORMAL,
          product: null,
          summary: '',
          quantity: null,
          unit: '',
          unitPrice: null,
          taxCategory: null,
          textLine: '',
        },
      ];
};
