import { SxProps } from '@mui/material';

import { AppColors } from './AppColors';
import { Fonts } from './AppEnums';

export const CommonHeight = {
  minHeight: '42px',
  display: 'flex',
  alignItems: 'center',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
};

export const TitleHeight = {
  fontWeight: Fonts.BOLD,
  height: '42px',
  alignItems: 'center',
  display: 'flex',
};

export const TableCellSx = {
  fontSize: '11px',
  color: AppColors.light.black,
  fontWeight: Fonts.REGULAR,
  lineHeight: '14px',
  letterSpacing: '0.04em',
};

export const TableTitleCellSx = {
  fontSize: '14px',
  color: AppColors.light.black,
  fontWeight: Fonts.BOLD,
  lineHeight: '23px',
  letterSpacing: '0.04em',
};

export const TableRowSx: SxProps = {
  height: '80px',
  '&:hover': { bgcolor: AppColors.light.lightBlue1 },
  cursor: 'pointer',
};
