import { AppColors } from '@crema/constants/AppColors';
import { TableRow, styled } from '@mui/material';

const AppTableRow = styled(TableRow)(() => ({
  height: '80px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: AppColors.light.lightBlue1,
  },
}));

export default AppTableRow;
