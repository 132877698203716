import { useAppStateContext } from '@crema/context/AppContextProvider/StateContextProvider';
import { RouterConfigData } from '@crema/types/models/Apps';
import List from '@mui/material/List';
import React from 'react';

import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import NavVerticalGroup from './VerticalNavGroup';

type Props = {
  routesConfig: RouterConfigData[];
};

const VerticalNav = ({ routesConfig }: Props) => {
  const { appState } = useAppStateContext();
  if (appState?.hiddenDrawer) {
    return null;
  }

  return (
    <List
      sx={{
        position: 'relative',
        padding: 0,
        backgroundColor: '#eaf6fc',
      }}
      component="div"
    >
      {routesConfig.map((item: RouterConfigData) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && <NavVerticalGroup item={item} level={0} />}

          {item.type === 'collapse' && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === 'item' && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default VerticalNav;
