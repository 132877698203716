import useIntl from 'react-intl/lib/src/components/useIntl';

export const IMPORT_STATUS = {
  REQUESTED: 'REQUESTED',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAIL: 'FAIL',
};

export const useConstantTrans = () => {
  const { messages } = useIntl();

  const honorificType = [
    { value: 'SIR', label: messages['customer.create.honorificType.sir'] },
    { value: 'MADAM', label: messages['customer.create.honorificType.madam'] },
    { value: 'EMPTY', label: messages['customer.create.honorificType.empty'] },
  ];

  return {
    honorificType,
  };
};
