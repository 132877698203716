export const AppColors = {
  light: {
    error: '#DF4C2C',
    blue01: '#2C9EDF',
    lightBlue1: '#2C9EDF1A',
    lightGray: '#00000014',
    black: '#454545',
    white: '#FFFFFF',
    red: '#DF4C2C',
    gray: '#0000004D',
    lightGrayBlue: '#F4F7F8',
  },
};
