import { AppColors } from '@crema/constants/AppColors';
import { styled } from '@mui/material/styles';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

const StyledDatePicker = styled(DateTimePicker)<{ error?: boolean }>(
  ({ error }) => ({
    '& .MuiInputBase-root': {
      cursor: 'pointer',
      fontFamily: 'NotoSansJP',
      fontSize: '14px',
      height: '42px !important',
      border: '1px solid transparent',
      borderRadius: '4px',
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: error ? '#d32f2f !important' : '#0000004D !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: error
        ? '#d32f2f !important'
        : AppColors.light.blue01 + '!important',
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      background: '#eeeeee !important',
      opacity: 0.7,
    },
    '&:hover .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0000004D !important',
    },
  }),
);

type DateTimePickerType = DateTimePickerProps<unknown | Dayjs | null> &
  React.RefAttributes<HTMLDivElement> & {
    error?: boolean;
  };

const CustomDateTimePicker = (props: Any<DateTimePickerType>) => {
  return (
    <StyledDatePicker
      {...props}
      slotProps={{
        textField: {
          variant: 'outlined',
          fullWidth: true,
          // className: props.error ? 'date-error' : '',
          error: !!props.error,
        },
      }}
      error={props.error}
    />
  );
};

export default CustomDateTimePicker;
