import { UserRole } from '@crema/constants/AppConst';
import { RoutePermittedRole } from '@crema/constants/AppEnums';

export const checkPermission = (routeRole: any, userRole: any) => {
  if (!routeRole || !routeRole) {
    return true;
  }

  if (userRole && Array.isArray(userRole) && !Array.isArray(routeRole)) {
    return userRole.indexOf(routeRole) >= 0;
  }
  if (routeRole.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeRole)) {
    return routeRole.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeRole.indexOf(userRole as string) >= 0;
};

export const ProcessRoles = (
  inputArray: Array<string>,
): Array<RoutePermittedRole> => {
  const result: Array<RoutePermittedRole> = [RoutePermittedRole.User];

  if (inputArray.includes('ACCESS_ACCOUNT')) {
    result.push(RoutePermittedRole.SettingAccount);
  }

  if (inputArray.includes('CREATE_VARIOUS_SETTING')) {
    result.push(RoutePermittedRole.SettingVarius);
  }

  if (inputArray.includes('CREATE_NOTIFICATION')) {
    result.push(RoutePermittedRole.SettingNotification);
  }

  return result;
};

export const canEdit = (idDepartment: number | string): boolean => {
  if (idDepartment === undefined) {
    return false;
  }

  let authorities: Array<string> = [];
  let departments: Array<any> = [];
  const storeRole = localStorage.getItem('authorities');
  const storeDepartments = localStorage.getItem('departments');
  if (storeRole) {
    authorities = JSON.parse(storeRole) || [];
  }

  if (authorities.includes(UserRole.CreateEditOtherDepartment)) {
    return true;
  } else {
    if (storeDepartments) {
      departments = JSON.parse(storeDepartments) || [];
    }
    return departments.some((item) => item.id === idDepartment);
  }
};

export const isSameDepartment = (idDepartment: number | string): boolean => {
  if (idDepartment === undefined) {
    return false;
  }

  let departments: Array<any> = [];
  const storeDepartments = localStorage.getItem('departments');
  if (storeDepartments) {
    departments = JSON.parse(storeDepartments) || [];
  }
  return departments.some((item) => item.id === idDepartment);
};
